import React, { useState } from 'react';

const initalUserForm: IUserFormContext = {
  form: {
    firstName: {
      placeholder: 'Förnamn',
      name: 'firstName',
      value: '',
      invalidReason: '',
    },
    lastName: {
      placeholder: 'Efternamn',
      name: 'lastName',
      value: '',
      invalidReason: '',
    },
    street: {
      placeholder: 'Adress',
      name: 'street',
      value: '',
      invalidReason: '',
    },
    postalCode: {
      placeholder: 'Postkod',
      name: 'postalCode',
      value: '',
      invalidReason: '',
    },
    city: {
      placeholder: 'Ort',
      name: 'city',
      value: '',
      invalidReason: '',
    },
    email: {
      placeholder: 'Epost',
      name: 'email',
      value: '',
      invalidReason: '',
    },
    telephone: {
      placeholder: 'Telefon',
      name: 'telephone',
      value: '',
      invalidReason: '',
    },
    country: {
      placeholder: 'Land',
      name: 'country',
      value: '',
      invalidReason: '',
    },
  },
  setFieldValue: () => {},
  setFieldInvalid: () => {},
};

export interface IUserFormContext {
  form: IForm;
  setFieldValue: {
    (name: string, value: string): void;
  };
  setFieldInvalid: {
    (name: string, invalidReason: string): void;
  };
}

export interface IForm {
  firstName: IInputField;
  lastName: IInputField;
  street: IInputField;
  city: IInputField;
  postalCode: IInputField;
  telephone: IInputField;
  email: IInputField;
  country: IInputField;
}
export interface IInputField {
  placeholder: string;
  name: string;
  value: string;
  invalidReason: string;
}
const FormContext = React.createContext<IUserFormContext>(initalUserForm);

export const FormContextProvider = ({
  children,
  initialValue = initalUserForm,
}: {
  children: React.ReactChild;
  initialValue?: IUserFormContext;
}) => {
  const [paymentForm, setPaymentForm] = useState(initialValue);

  const setFieldValue = (name: string, value: string) => setPaymentForm((prevContext) => {
    const newContext = {
      ...prevContext,
    };
    newContext.form[name as keyof IForm].value = value;
    return newContext;
  });

  const setFieldInvalid = (name: string, invalidReason: string) => setPaymentForm((prevContext) => {
    const newContext = {
      ...prevContext,
    };
    newContext.form[name as keyof IForm].invalidReason = invalidReason;
    return newContext;
  });

  return (
    <FormContext.Provider
      value={{
        ...paymentForm,
        setFieldValue,
        setFieldInvalid,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
