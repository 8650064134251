import { ISubscription } from '~interfaces/subscriptions';

const utils = {
  compNextRun: (subA: ISubscription, subB: ISubscription) => {
    if (subA.nextRun < subB.nextRun) {
      return -1;
    }
    if (subA.nextRun > subB.nextRun) {
      return 1;
    }
    return 0;
  },
};

export const transform = {
  renameCardNetwork: (cardNetwork: string) => {
    if (
      cardNetwork === ('mc' || 'mcalphabankbonus' || 'mcprepaidanonymous')
      || cardNetwork.includes('mc_')
    ) {
      return 'Mastercard';
    }
    if (cardNetwork.includes('visa' || 'electron_') || cardNetwork === 'vpay') {
      return 'VISA';
    }
    return 'Credit Card';
  },
};

export default utils;
