import moment from 'moment';
import { ICardReference, IBillingAddress } from '~interfaces/cardInfo';
import { IReceiptDetail } from '~interfaces/receipt';

export const templateCardReferences: ICardReference[] = [
  {
    cardNetwork: 'VISA',
    PANTruncation: '5088',
    expiryMonth: '06',
    expiryYear: '22',
  },
  {
    cardNetwork: 'mc',
    PANTruncation: '1234',
    expiryMonth: '01',
    expiryYear: '70',
  },
];

export const templateBillingAddress: IBillingAddress = {
  firstName: 'Joakim',
  lastName: 'Sjöquist',
  email: 'joakim.sjoquist@knightec.se',
  telephone: '0701499148',
  street: 'Gatstigen 11',
  postalCode: '16543',
  city: 'Stockholm',
  country: 'Sweden',
};

export const topUpProducts = [
  {
    key: '5gb',
    text: '5 GB',
    sub: '69 KR',
  },
  {
    key: '10gb',
    text: '10 GB',
    sub: '99 KR',
  },
  {
    key: '15gb',
    text: '15 GB',
    sub: '169 KR',
  },
  {
    key: '20gb',
    text: '20 GB',
    sub: '199 KR',
  },
];

export const PAY_DRIVE_INFO = {
  licensePlate: 'ABC123',
  imei: Math.random().toString().slice(2, 18),
  wifi: 'Active',
};

export const availableSubscriptions = [
  {
    key: '0',
    text: 'Telia Sense Basic',
    sub: 'GRATIS',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam placerat nisl nibh, at commodo neque rhoncus ut.',
    bullets: [
      'Lorem ipsum dolor sit amet',
      'consectetur adipiscing elit',
      'Aliquam placerat nisl nibh',
    ],
  },
  {
    key: '1',
    text: 'Telia Sense Premium',
    sub: '99 KR',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam placerat nisl nibh, at commodo neque rhoncus ut.',
    active: true,
    bullets: [
      'Lorem ipsum dolor sit amet',
      'consectetur adipiscing elit',
      'Aliquam placerat nisl nibh',
    ],
  },
  {
    key: '2',
    text: 'Telia Sense Family',
    sub: '169 KR',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam placerat nisl nibh, at commodo neque rhoncus ut.',
    bullets: [
      'Lorem ipsum dolor sit amet',
      'consectetur adipiscing elit',
      'Aliquam placerat nisl nibh',
    ],
  },
];

export const receiptContent = [
  {
    id: 0,
    date: moment.utc().toISOString(),
    status: 'Behandlas',
    price: '99kr',
    text: moment.utc().format('MMMM'),
  },
  ...new Array(10).fill(0).map((_, index) => ({
    id: index + 1,
    date: moment
      .utc()
      .subtract(index + 1, 'months')
      .toISOString(),
    status: 'Betald',
    price: '99kr',
    text: moment
      .utc()
      .subtract(index + 1, 'months')
      .format('MMMM'),
  })),
];

export const receiptsDetailed: { [key: string]: IReceiptDetail } = {
  0: {
    date: receiptContent.find(({ id }) => id === 0)?.date || '',
    seller: 'Telia Company AB',
    paymentMethod: 'Mastercard 0048',
    product: {
      name: 'Telia Sense Månadsvis',
    },
    price: {
      totalFormatted: '99,00 kr',
      totalVatFormatted: '19.80 kr',
      vatGroup: 0.25,
    },
  },
};
