import React from 'react';
import { Link } from 'react-router-dom';
import './linkItemRemove.scss';

interface IProps {
  title: string;
  subTitle?: string;
  description?: string;
  icon?: string;
  noBorder?: boolean;
  to: {
    pathname: string;
    data?: object;
  };
}

const LinkItemRemove = (props: IProps) => {
  const {
    title, subTitle, description, to, icon, noBorder,
  } = props;

  return (
    <div>
      <Link
        className={`link-item-remove-wrapper${subTitle ? ' profile' : ''}`}
        style={noBorder ? { borderBottom: 'none' } : undefined}
        to={{ pathname: to.pathname, data: to.data }}
      >
        <div className="title-wrapper">
          {subTitle ? (
            <>
              <h2>{title}</h2>
              <p className="sub-title">{subTitle}</p>
            </>
          ) : (
            <p className="title">{title}</p>
          )}
        </div>
        {description && <p className="description">{description}</p>}
        {icon && (
          <div className="icon-wrapper">
            <img src={icon} alt="" />
          </div>
        )}
      </Link>
    </div>
  );
};

export default LinkItemRemove;
