import React from 'react';
import LoadingTeliaLogo from './loading-telia-logo.gif';
import './processing.scss';

interface IProps {
  altText: string;
}

const Processing = ({ altText }: IProps) => (
  <div className="processing-wrapper">
    <img className="loader" src={LoadingTeliaLogo} alt={altText} />
  </div>
);

export default Processing;
