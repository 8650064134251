import React, { useContext } from 'react';
import intl from 'react-intl-universal';
import ScrollToTop from '~components/scrollToTop/scrollToTop';
import BackButtonHeader from '~components/backButtonHeader/backButtonHeader';
import GridItemWithIcon from '~components/gridItemWithIcon/gridItemWithIcon';
import { Store } from '../store';
import '~shared/styles/webView.scss';
import '~shared/styles/formHeader.scss';
import { ISubscription } from '~interfaces/subscriptions';

const SubscriptionView = () => {
  const { state } = useContext(Store);

  const subscriptions: ISubscription[] = state.user?.subscriptions ?? [];

  const activeSubs = subscriptions.filter((sub: ISubscription) => {
    if (sub.isPaydrive) {
      return sub.status !== 'complete' || sub.premiumSubscription?.status !== 'complete';
    }
    return sub.status !== 'complete';
  });

  const filteredActiveSubs = activeSubs.map((subscription: any) => {
    if (subscription.isPaydrive) {
      if (['active', 'payment_failed', 'paused', 'canceled'].includes(subscription.premiumSubscription?.status)) {
        return subscription.premiumSubscription;
      }
    }
    return subscription;
  });

  const inactiveSubs = subscriptions.filter((sub: ISubscription) => sub.status === 'complete');

  return (
    <div className="web-view-container">
      <ScrollToTop />
      <div className="row">
        <BackButtonHeader title={intl.get('SUBSCRIPTION__MANAGE_SUBSCRIPTION')} to="/account" />
      </div>
      <section className="row section-wrapper">
        {activeSubs.length > 0 && (
          <GridItemWithIcon
            pageTitle={intl.get('SUBSCRIPTION__ACTIVE_SUBSCRIPTIONS')}
            subscriptions={filteredActiveSubs}
          />
        )}
        {inactiveSubs.length > 0 && (
          <GridItemWithIcon
            pageTitle={intl.get('SUBSCRIPTION__INACTIVE_SUBSCRIPTIONS')}
            subscriptions={inactiveSubs}
          />
        )}
      </section>
    </div>
  );
};

export default SubscriptionView;
