import React from 'react';
import { Checkmark, AlertFilled, Canceled } from '~shared/svg-icons';
import './subscriptionListItem.scss';
import SubscriptionDetails from '~components/subscriptionDetails/subscriptionDetails';
import { ISubscription } from '~interfaces/subscriptions';

interface IProps {
  title: string;
  borderBottom?: boolean;
  subscription: ISubscription;
}

const SubscriptionListItem = ({ title, subscription, borderBottom }: IProps) => (
  <div className="list-item-container" style={borderBottom ? { borderBottom: 'none' } : undefined}>
    <div className="list-item-wrapper">
      <p className="title">{title}</p>
      {subscription.description && <p className="description">{subscription.description}</p>}
      <div className="icon-wrapper">
        {['active'].includes(subscription.status) && <Checkmark />}
        {['canceled', 'paused'].includes(subscription.status) && <AlertFilled />}
        {['complete', 'payment_failed'].includes(subscription.status) && <Canceled />}
      </div>
    </div>
    {subscription && <SubscriptionDetails subscription={subscription} />}
  </div>
);

export default SubscriptionListItem;
