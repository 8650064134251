import React, { useEffect, useState, useContext } from 'react';
import intl from 'react-intl-universal';
import ProfileEditContext from '~contexts/profileEditContext';

interface IProps {
  type?: string;
  inputName: string;
  placeholder: string;
  value: string;
  readOnly?: boolean;
  disabled?: boolean;
  invalidReason?: string | boolean;
}

const CustomInput = ({
  inputName,
  type,
  placeholder,
  value,
  readOnly,
  disabled,
  invalidReason,
}: IProps) => {
  const profileContext = useContext(ProfileEditContext);
  const [fieldFilled, setFieldFilled] = useState('');
  const [fieldVisited, setFieldVisited] = useState(false);

  const checkFieldValid = (fieldName: string, fieldValue: string) => {
    const field = document.getElementById(`profileInfo-${inputName}`) as HTMLInputElement;
    if (fieldVisited && field) {
      let errorMessage = fieldValue
        ? ''
        : intl.get('FIELD_VALIDATION__REQUIRED', { fieldName: placeholder });

      if (type) {
        if (!field.checkValidity()) {
          errorMessage = intl.get('FIELD_VALIDATION__INVALID', { fieldName: placeholder });
        }
      }
      profileContext.setFieldInvalid(fieldName, errorMessage);
    }
  };

  const filledInput = (input: string) => {
    if (input) {
      setFieldFilled('adyenFormItem-label--filled');
    } else {
      setFieldFilled('');
    }
  };

  useEffect(() => {
    filledInput(value);
  }, [value]);

  useEffect(() => {
    checkFieldValid(inputName, value);
  }, [fieldVisited, value]);

  return (
    <div
      className={
        invalidReason
          ? 'adyenFormItem-field-wrapper'
          : 'adyenFormItem-field-wrapper adyenFormItem-field-wrapper--error'
      }
    >
      <label
        htmlFor={`label-${inputName}`}
        className={
          invalidReason
            ? 'adyenFormItem-label adyenFormItem-label--error'
            : `adyenFormItem-label ${fieldFilled}`
        }
      >
        <span className="adyenFormItem-label__text">{placeholder}</span>
        <input
          className="adyenFormItem-label__input"
          required
          type={type ?? 'text'}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          onBlur={() => setFieldVisited(true)}
          onChange={(e) => profileContext.setFieldValue(inputName, e.target.value)}
          id={`profileInfo-${inputName}`}
        />
      </label>
      {invalidReason && <span className="adyenFormItem-error-message">{`${invalidReason}`}</span>}
    </div>
  );
};

export default CustomInput;
