import { IReceiptListItem } from '~interfaces/receipt';
import { IBillingAddress, ICardReferenceBody } from '~interfaces/cardInfo';
import { ISubscriptionBody } from '~interfaces/subscriptions';
import { IProfileInformation } from '~interfaces/userData';
import { receiptContent, receiptsDetailed } from './content';
import { BACKEND_API_BASE_URL } from '~config/config';

class Api {
  /* eslint-disable */
  // Receipts
  public getReceiptsPaged = async (from: number, to: number): Promise<IReceiptListItem[]> =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(receiptContent.slice(from, to) as IReceiptListItem[]);
      }, 300);
    });

  public getReceipt = async (id: string) => {
    await new Promise((resolve) => setTimeout(resolve, 300));
    const receipt = receiptsDetailed[id];
    if (receipt) return receipt;
    throw new Error('error');
  };

  /* eslint-enable */
  public removeSubscription = async (subscriptionId: number, accessToken: string) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(
      `${BACKEND_API_BASE_URL}/users/mine/subscriptions/${subscriptionId}`,
      requestOptions,
    );

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `DELETE /users/mine/subscriptions returned ${status} ${statusText}: ${JSON.stringify(
          body,
          null,
          2,
        )}`,
      );
    }
  };

  public activateSubscription = async (subscriptionId: number, accessToken: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(
      `${BACKEND_API_BASE_URL}/users/mine/subscriptions/${subscriptionId}/activate`,
      requestOptions,
    );

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `POST /users/mine/subscriptions/${subscriptionId}/activate returned ${status} ${statusText}: ${JSON.stringify(
          body,
          null,
          2,
        )}`,
      );
    }
  };

  public removeAccount = async (accessToken: string) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(`${BACKEND_API_BASE_URL}/queues/users/mine`, requestOptions);

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `DELETE /queues/users/mine returned ${status} ${statusText}: ${JSON.stringify(
          body,
          null,
          2,
        )}`,
      );
    }
  };

  public setProfileInformation = async (
    userInformation: IProfileInformation,
    accessToken: string,
  ): Promise<IProfileInformation> => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userInformation),
    };

    const response = await fetch(`${BACKEND_API_BASE_URL}/users/mine`, requestOptions);

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `PUT /users/mine returned ${status} ${statusText}: ${JSON.stringify(body, null, 2)}`,
      );
    }
    return response.json();
  };

  public initializeSaveCard = async (creditCardInfo: any, accessToken: string): Promise<void> => {
    const requestOptions = {
      method: 'POST',
      headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' },
      body: JSON.stringify(creditCardInfo),
    };

    const response = await fetch(`${BACKEND_API_BASE_URL}/cards`, requestOptions);

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `POST /cards returned ${status} ${statusText}: ${JSON.stringify(body, null, 2)}`,
      );
    }
  };

  public getTransactionStatus = async (
    accessToken: string,
  ): Promise<{
    status: 'succeeded' | 'failed' | 'pending' | 'redirect';
    type: 'save-card' | 'payment';
  }> => {
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const response = await fetch(
      `${BACKEND_API_BASE_URL}/transactions/mine/status`,
      requestOptions,
    );

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `GET /transactions/mine/status returned ${status} ${statusText}: ${JSON.stringify(
          body,
          null,
          2,
        )}`,
      );
    }

    return response.json();
  };

  public getTransactionRedirect = async (
    accessToken: string,
    redirectType: string,
  ): Promise<{
    method: 'POST';
    url: string;
    data: {
      MD: string;
      PaReq: string;
      TermUrl: string;
    };
  }> => {
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const response = await fetch(
      `${BACKEND_API_BASE_URL}/transactions/mine/redirect${
        redirectType != null && redirectType.length > 0 ? `/${redirectType}` : ''
      }`,
      requestOptions,
    );

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `GET /transactions/mine/redirect returned ${status} ${statusText}: ${JSON.stringify(
          body,
          null,
          2,
        )}`,
      );
    }

    return response.json();
  };

  public provideTransactionInput = async (
    transactionInput: {
      MD: string;
      PaRes: string;
    },
    accessToken: string,
  ): Promise<void> => {
    const requestOptions = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(transactionInput),
    };

    const response = await fetch(`${BACKEND_API_BASE_URL}/transactions/mine`, requestOptions);

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `PATCH /transactions/mine returned ${status} ${statusText}: ${JSON.stringify(
          body,
          null,
          2,
        )}`,
      );
    }
  };

  public setBillingInformation = async (
    userInformation: IBillingAddress,
    accessToken: string,
  ): Promise<void> => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userInformation),
    };

    const response = await fetch(
      `${BACKEND_API_BASE_URL}/users/mine/billing-address`,
      requestOptions,
    );

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `PUT /users/mine/billing-address returned ${status} ${statusText}: ${JSON.stringify(
          body,
          null,
          2,
        )}`,
      );
    }
  };

  public sendReceiptByMail = async (receiptId: string, email: string): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('sending receipt with id', receiptId, 'to', email);
    throw new Error('Function sendReceiptByMail is not implemented!');
  };

  public getBillingAddress = async (accessToken: string): Promise<IBillingAddress> => {
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const response = await fetch(
      `${BACKEND_API_BASE_URL}/users/mine/billing-address`,
      requestOptions,
    );

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `GET /users/mine/billing-address returned ${status} ${statusText}: ${JSON.stringify(
          body,
          null,
          2,
        )}`,
      );
    }

    return response.json();
  };

  public getCardReferences = async (accessToken: string): Promise<ICardReferenceBody> => {
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const response = await fetch(
      `${BACKEND_API_BASE_URL}/users/mine/card-references`,
      requestOptions,
    );

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `GET /users/mine/card-references returned ${status} ${statusText}: ${JSON.stringify(
          body,
          null,
          2,
        )}`,
      );
    }

    return response.json();
  };

  public updateCardReference = async (accessToken: string): Promise<void> => {
    const requestOptions = {
      method: 'POST',
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const response = await fetch(
      `${BACKEND_API_BASE_URL}/users/mine/card-references`,
      requestOptions,
    );

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `POST /users/mine/card-references returned ${status} ${statusText}: ${JSON.stringify(
          body,
          null,
          2,
        )}`,
      );
    }
  };

  public getUserSubscriptions = async (accessToken: string): Promise<ISubscriptionBody> => {
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const response = await fetch(
      `${BACKEND_API_BASE_URL}/users/mine/subscriptions`,
      requestOptions,
    );

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `GET /users/mine/subscriptions returned ${status} ${statusText}: ${JSON.stringify(
          body,
          null,
          2,
        )}`,
      );
    }

    return response.json();
  };

  public createUserSubscription = async (accessToken: string): Promise<void> => {
    const requestOptions = {
      method: 'POST',
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const response = await fetch(
      `${BACKEND_API_BASE_URL}/users/mine/subscriptions`,
      requestOptions,
    );

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `POST /users/mine/subscriptions returned ${status} ${statusText}: ${JSON.stringify(
          body,
          null,
          2,
        )}`,
      );
    }
  };

  public getAccessToken = async (
    oneTimePass: string,
  ): Promise<{
    accessToken: string;
  }> => {
    const requestOptions = {
      method: 'GET',
    };

    const response = await fetch(`${BACKEND_API_BASE_URL}/sessions/${oneTimePass}`, requestOptions);

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `GET /sessions/:otp returned ${status} ${statusText}: ${JSON.stringify(body, null, 2)}`,
      );
    }

    return response.json();
  };

  public checkForUnpaidOrders = async (
    subscriptionId: number,
    accessToken: string,
  ): Promise<string> => {
    const requestOptions = {
      method: 'POST',
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const response = await fetch(
      `${BACKEND_API_BASE_URL}/queues/unpaid-orders/${subscriptionId}`,
      requestOptions,
    );

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `POST /queues/unpaid-orders/${subscriptionId} returned ${status} ${statusText}: ${JSON.stringify(
          body,
          null,
          2,
        )}`,
      );
    }
    return response.json();
  };

  public getOrderStatus = async (
    uuid: string,
    accessToken: string,
  ): Promise<{
    uuid: string;
    status: string;
    error?: string;
    timestamp: string;
  }> => {
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    const response = await fetch(
      `${BACKEND_API_BASE_URL}/queues/unpaid-orders/entries/${uuid}`,
      requestOptions,
    );

    if (!response.ok) {
      const { status, statusText } = response;
      const body = await response.json();
      throw new Error(
        `GET /queues/unpaid-orders/entries/:uuid returned ${status} ${statusText}: ${JSON.stringify(
          body,
          null,
          2,
        )}`,
      );
    }
    return response.json();
  };
}

const instance = new Api();

export { instance as Api };
export default instance;
