import React, { useContext, useState } from 'react';
import intl from 'react-intl-universal';
import { Store } from '../store';
import { ProfileEditContextProvider, IProfileEditContext } from '~contexts/profileEditContext';
import { IProfileInformation } from '~interfaces/userData';
import ButtonNavigationHeader from '~components/buttonNavigationHeader/buttonNavigationHeader';
import ProfileEditForm from '~components/profileEditForm/profileEditForm';
import '~components/AdyenStyledForm/AdyenStyledForm.scss';
import '~shared/styles/webView.scss';
import '~shared/styles/titleHeader.scss';

interface IProfileEditView {
  userInfo: IProfileInformation;
  isValid: boolean;
}

const ProfileEditView = () => {
  const {
    state: { user },
  } = useContext(Store);

  const [profileInfo, setProfileInfo] = useState<IProfileEditView>({
    userInfo: {} as IProfileInformation,
    isValid: false,
  });

  const initalUserForm: IProfileEditContext = {
    form: {
      firstName: {
        label: 'firstName',
        value: user?.givenName ?? '',
        invalidReason: '',
      },
      lastName: {
        label: 'lastName',
        value: user?.familyName ?? '',
        invalidReason: '',
      },
      email: {
        label: 'email',
        value: user?.email ?? '',
        invalidReason: '',
      },
    },
    setFieldValue: () => {},
    setFieldInvalid: () => {},
  };

  return (
    <div className="web-view-container new-payment-view">
      <div className="row">
        <ButtonNavigationHeader
          title={`${intl.get('EDIT')} ${intl.get('PROFILE')}`}
          previous={{ pathname: '/account/profile', description: intl.get('CANCEL') }}
          to={{
            pathname: '/account/profile/processing',
            data: profileInfo,
            description: intl.get('SAVE'),
            active: profileInfo.isValid,
          }}
        />
      </div>
      <section className="row section-wrapper new-payment-view__payment-wrapper">
        <div className="button-wrapper">
          <h2 className="section-title">{intl.get('NEW_CONTACT_INFORMATION')}</h2>
        </div>
        {user && (
          <ProfileEditContextProvider initialValue={initalUserForm}>
            <ProfileEditForm onChange={setProfileInfo} />
          </ProfileEditContextProvider>
        )}
      </section>
    </div>
  );
};

export default ProfileEditView;
