import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import BackButtonHeader from '~components/backButtonHeader/backButtonHeader';
import '~shared/styles/webView.scss';
import '~shared/styles/titleHeader.scss';
import '~shared/styles/footer.scss';
import '~shared/styles/removeSubscriptionView.scss';
import { ISubscription } from '~interfaces/subscriptions';
import TCSenseCar from '~components/subscriptionDetails/icons/TCSenseCar.svg';
import TCInfo from '~components/subscriptionDetails/icons/TCInfo.svg';
import TCWireless from '~components/subscriptionDetails/icons/TCWireless.svg';
import TCInvoice from '~components/subscriptionDetails/icons/TCInvoice.svg';
import { Checkmark, AlertFilled, Canceled } from '~shared/svg-icons/index';
import Text from '~components/text/text';

interface IProps {
  subscription: ISubscription;
}

const RemoveSubscriptionView = ({
  location: { data },
}: {
  location: {
    data: IProps;
  };
}) => {
  const { subscription } = data;

  const [userAccepted, setUserAccepted] = useState(false);

  const description = subscription.isPaydrive
    ? intl.get('SUBSCRIPTION_DOWNGRADE__ARE_YOU_SURE')
    : intl.get('SUBSCRIPTION_REMOVE__ARE_YOU_SURE');

  const userAgreementDescription = subscription.isPaydrive
    ? intl.get('SUBSCRIPTION_REMOVE__CANCEL_PREMIUM')
    : intl.get('SUBSCRIPTION_REMOVE__CANCEL_SUB');

  return (
    <div className="web-view-container remove-subscription-view">
      <div className="row">
        <BackButtonHeader
          title={`${subscription.licensePlate.toUpperCase()}`}
          to="/account/subscription"
        />
      </div>
      <section className="row remove-section-wrapper">
        <h2 className="remove-subscription-title">{description}</h2>
        <div className="remove-subscription-container">
          <div className="grid-item">
            <div className="icon-wrapper">
              {['active'].includes(subscription.status) && <Checkmark />}
              {['canceled', 'paused'].includes(subscription.status) && <AlertFilled />}
              {['complete', 'payment_failed'].includes(subscription.status) && <Canceled />}
            </div>
            <div className="content-wrapper">
              <Text bold>{`${subscription.title} ${subscription.description}`}</Text>
            </div>
          </div>
          <div className="subscription-container">
            {subscription.price !== 0
              && subscription.freeMonths > -1
              && subscription.usedMonths >= subscription.freeMonths && (
                <div className="subscription-item-container subscription-item-container--large">
                  <img src={TCInvoice} className="subscription-icon-wrapper--large" alt="Price" />
                  <p className="subscription-item-price-text">{`${subscription.price} ${subscription.currency}`}</p>
                </div>
            )}
            {subscription.licensePlate && (
              <div className="subscription-item-container subscription-item-container--large">
                <img src={TCSenseCar} className="subscription-icon-wrapper--large" alt="Car" />
                <p className="subscription-item-text">{`${subscription.licensePlate.toUpperCase()}`}</p>
              </div>
            )}
            {subscription.imei && (
              <div className="subscription-item-container subscription-item-container--large">
                <img src={TCInfo} className="subscription-icon-wrapper--large" alt="IMEI" />
                <p className="subscription-item-text">{`${subscription.imei}`}</p>
              </div>
            )}
            {subscription.status && (
              <div className="subscription-item-container subscription-item-container--large">
                <img src={TCWireless} className="subscription-icon-wrapper--large" alt="WIFI" />
                {subscription.isPaydrive && (
                  <p className="subscription-item-text">
                    {subscription.isPremium && subscription.wifiEnabled
                      ? intl.get('ENABLED')
                      : intl.get('DISABLED')}
                  </p>
                )}
                {!subscription.isPaydrive && (
                  <p className="subscription-item-text">
                    {subscription.wifiEnabled ? intl.get('ENABLED') : intl.get('DISABLED')}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="row remove-subscription-footer">
        <div className="row checkbox-container">
          <label htmlFor="acceptRemoveCheckbox" className="checkbox">
            <span>{userAgreementDescription}</span>
            <input
              id="acceptRemoveCheckbox"
              name="acceptRemoveCheckbox"
              type="checkbox"
              onChange={(event) => setUserAccepted(event.target.checked)}
            />
            <span className="checkmark" />
          </label>
        </div>
        <div className="row footer">
          {userAccepted ? (
            <Link
              className="button no-border red"
              to={{
                pathname: '/account/subscription/remove/processing',
                state: { subscription },
              }}
            >
              {`${intl.get('CANCEL')}
            ${subscription.licensePlate.toUpperCase()}`}
            </Link>
          ) : (
            <button type="button" disabled className="button no-border gray">
              {`${intl.get('CANCEL')}
            ${subscription.licensePlate.toUpperCase()}`}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RemoveSubscriptionView;
