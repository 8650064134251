import React from 'react';
import LoadingTeliaLogo from './loading-telia-logo.gif';
import './processingSmall.scss';

interface IProps {
  altText: string;
}

const ProcessingSmall = ({ altText }: IProps) => (
  <div className="processing-wrapper--small">
    <img className="loader" src={LoadingTeliaLogo} alt={altText} />
  </div>
);

export default ProcessingSmall;
