import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import AdyenComponent from '@dewire/adyen-react';
import AdyenStyledForm from '~components/AdyenStyledForm/AdyenStyledForm';
import FooterText from '~components/FooterText/FooterText';
import { ADYEN_ORIGIN_KEY, ADYEN_ENV } from '~config/config';
import { FormContextProvider } from '~contexts/formContext';
import { IBillingAddress, IPaymentMethod } from '~interfaces/cardInfo';
import '~shared/styles/webView.scss';
import '~shared/styles/titleHeader.scss';
import '~shared/styles/registrationView.scss';
import '~shared/styles/checkbox.scss';

const RegistrationView = () => {
  const [creditCardInfo, setCreditCardInfo] = useState<{
    data: IPaymentMethod;
    isValid: boolean;
  }>({ data: {} as IPaymentMethod, isValid: false });
  const [userInfo, setUserInfo] = useState<{
    data: IBillingAddress;
    isValid: boolean;
  }>({ data: {} as IBillingAddress, isValid: false });
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  return (
    <div className="web-view-container new-payment-view">
      <section className="row section-wrapper new-payment-view__payment-wrapper">
        <div className="row">
          <div id="title-header" className="purple" style={{ margin: '2em auto 1em auto' }}>
            <h2>{intl.get('REGISTER_NEW_SUBSCRIPTION')}</h2>
          </div>
        </div>
        <h2 className="section-title">{intl.get('NEW_PAYMENT__ENTER_NEW_PAYMENT_METHOD')}</h2>
        <div className="row">
          <h3>{intl.get('CARD_DETAILS__CUSTOMER_INFORMATION')}</h3>
          <FormContextProvider>
            <AdyenStyledForm onChange={setUserInfo} />
          </FormContextProvider>
        </div>
        <div className="row">
          <h3>{intl.get('CARD_DETAILS')}</h3>
          <AdyenComponent
            onChange={setCreditCardInfo}
            config={{
              locale: intl.getInitOptions().currentLocale,
              environment: ADYEN_ENV,
              originKey: ADYEN_ORIGIN_KEY,
            }}
          />
        </div>
        <div className="row">
          <label htmlFor="termsCheckbox" className="checkbox">
            <span>
              {intl.get('I_ACCEPT')}
              {' '}
              <a href={intl.get('TOC_URL')} target="_blank" rel="noopener noreferrer">
                {intl.get('TELIA_TOC')}
              </a>
            </span>
            <input
              id="termsCheckbox"
              name="termsCheckbox"
              type="checkbox"
              onChange={(event) => setHasAcceptedTerms(event.target.checked)}
            />
            <span className="checkmark" />
          </label>
        </div>
      </section>
      <div className="row footer">
        {userInfo.isValid && creditCardInfo.isValid && hasAcceptedTerms ? (
          <Link
            className="button no-border green"
            to={{
              pathname: '/registration/processing',
              formData: {
                userInfo: userInfo.data,
                creditCardInfo: creditCardInfo.data,
              },
            }}
          >
            {intl.get('REGISTER')}
          </Link>
        ) : (
          <button type="button" disabled className="button no-border gray">
            {intl.get('REGISTER')}
          </button>
        )}
        <FooterText text={intl.get('NEW_PAYMENT__FOOTER_TEXT')} disabled />
      </div>
    </div>
  );
};
export default RegistrationView;
