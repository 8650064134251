import React from 'react';
import { Link } from 'react-router-dom';
import Text from '~components/text/text';
import ArrowLeft from './arrowLeft';
import './buttonNavigationHeader.scss';

interface IProps {
  title: string;
  previous: {
    pathname: string;
    description?: string;
  };
  to: {
    description: string;
    pathname: string;
    data?: any;
    icon?: string;
    active: boolean;
  };
}

const ButtonNavigationHeader = ({ to, previous, title }: IProps) => {
  const cssTextIcon = to.icon ? 'button-navigation-text-icon' : '';
  const cssTextActive = to.active
    ? 'button-navigation-text--active'
    : 'button-navigation-text--inactive';
  return (
    <div className="button-navigation-header-wrapper">
      <Link to={previous.pathname}>
        {previous.description ? <p>{previous.description}</p> : <ArrowLeft />}
      </Link>
      {title && (
        <div className="title">
          <Text bold>{title}</Text>
        </div>
      )}
      {to.active ? (
        <Link to={to}>
          <p
            className={`${cssTextIcon} ${cssTextActive}`}
          >
            {to.description}
          </p>
          {to.icon && (
            <img
              className="button-navigation-header-icon"
              src={to.icon}
              alt={`${to.description}-icon`}
            />
          )}
        </Link>
      ) : (
        <div>
          <p className={`${cssTextIcon} ${cssTextActive}`}>{to.description}</p>
          {to.icon && (
            <img
              className="button-navigation-header-icon"
              src={to.icon}
              alt={`${to.description}-icon`}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ButtonNavigationHeader;
