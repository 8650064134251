import React from 'react';
import { Link } from 'react-router-dom';
import Text from '~components/text/text';
import ArrowLeft from './arrowLeft';
import './backButtonHeader.scss';

interface IProps {
  title: string;
  to: string;
}

const BackButtonHeader = ({ to, title }: IProps) => (
  <div className="back-button-header-wrapper">
    <Link to={to}>
      <ArrowLeft />
    </Link>
    {title && (
      <div className="title">
        <Text bold>{title}</Text>
      </div>
    )}
  </div>
);

export default BackButtonHeader;
