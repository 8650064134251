import React from 'react';
import { useField } from 'formik';
import './customSelect.scss';

const CustomSelect = ({ label, ...props }: any) => {
  const { id, name } = props;
  const [field, meta] = useField(props);
  // TODO: stop blindly passing on fields and props
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div className="select-wrapper">
      <div className="select-container">
        <select
          {...field}
          {...props}
          className={`select${meta.touched && meta.error ? ' error' : ''}`}
        />
        <div className={`select-outline${meta.value ? ' notched' : ''}`}>
          <label className="select-label" htmlFor={id || name}>
            {label}
          </label>
        </div>
      </div>
      {meta.touched && meta.error && <div className="select-helper-text error">{meta.error}</div>}
    </div>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

export default CustomSelect;
