import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import intl from 'react-intl-universal';
import { Api } from '~repositories/api';
import { IReceiptDetail } from '~interfaces/receipt';
import BackButtonHeader from '~components/backButtonHeader/backButtonHeader';
import ListItem from '~components/listItem/listItem';
import Processing from '~components/processing/processing';
import Text from '~components/text/text';
import { Store } from '../store';

// TODO - Fix receipt visual error on backbutton.
const ReceiptView = () => {
  const { id } = useParams() as any;
  const {
    state: { user },
  } = useContext(Store);
  const [receipt, setReceipt] = useState<IReceiptDetail>();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (id) {
      Api.getReceipt(id)
        .then(
          (res) => {
            setReceipt(res);
          },
          (err) => {
            if (err === 'NOT_FOUND') {
              setError(intl.get('RECEIPT__NOT_FOUND'));
            }
          },
        )
        .finally(() => setLoading(false));
    }
  });

  const onSendMailReceipt = () => {
    setProcessing(true);
    // @ts-ignore id will exist and the button is disabled if user has no email
    Api.sendReceiptByMail(id, user.email).finally(() => setProcessing(false));
  };

  if (loading) {
    return <Processing altText="loading receipts" />;
  }

  if (error || !receipt) {
    return (
      <div className="web-view-container">
        <div className="row">
          <BackButtonHeader title={intl.get('RECEIPT__TITLE')} to="/account/payment" />
        </div>
        <section className="section-wrapper">
          <Text>{error}</Text>
        </section>
      </div>
    );
  }

  return (
    <div className="web-view-container">
      {processing && <Processing altText="sending receipts" />}
      <div className="row">
        <BackButtonHeader title={intl.get('RECEIPT__TITLE')} to="/account/payment" />
      </div>

      <section className="row section-wrapper">
        <ListItem
          title={intl.get('DATE')}
          description={moment(receipt.date).local().format('YYYY-MM-DD')}
        />
        <ListItem title={intl.get('SELLER')} description={receipt.seller} />
        <ListItem title={intl.get('RECEIPT__PAYMENT_METHOD')} description={receipt.paymentMethod} />
        <ListItem title={intl.get('PRODUCT')} description={receipt.product.name} />
        <ListItem
          title={intl.get('RECEIPT__VAT', {
            VAT: receipt.price.vatGroup * 100,
          })}
          description={receipt.price.totalVatFormatted}
        />
        <ListItem title={intl.get('SUM')} description={receipt.price.totalFormatted} />
      </section>

      <div className="row button-wrapper">
        <div className="list-layout">
          <button
            type="button"
            disabled={!user?.email}
            className="button secondary purple"
            onClick={onSendMailReceipt}
          >
            {intl.get('RECEIPT__SEND_TO_MAIL')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReceiptView;
