import React from 'react';
import { Link } from 'react-router-dom';
import { Arrow } from '~shared/svg-icons';
import './linkItem.scss';

interface IProps {
  title: string;
  subTitle?: string;
  description?: string;
  noBorder?: boolean;
  disabled?: boolean;
  icon?: string;
  to: {
    pathname: string;
    state?: object;
  };
}

const LinkItem = (props: IProps) => {
  const {
    title, subTitle, description, to, noBorder, disabled, icon,
  } = props;

  return !disabled ? (
    <Link
      className={`link-item-wrapper ${subTitle ? ' profile' : ''}`}
      style={noBorder ? { borderBottom: 'none' } : undefined}
      to={{ pathname: to.pathname, state: to.state }}
    >
      <div className="title-wrapper">
        {subTitle ? (
          <>
            <h2>{title}</h2>
            <p className="sub-title">{subTitle}</p>
          </>
        ) : (
          <p className="title">{title}</p>
        )}
      </div>
      {description && <p className="description">{description}</p>}
      <div className="icon-wrapper">
        {icon
          ? <img src={icon} alt={title} /> : <Arrow />}
      </div>
    </Link>
  ) : (
    <div
      className={`link-item-wrapper ${subTitle ? ' profile' : ''}`}
      style={noBorder ? { borderBottom: 'none' } : undefined}
    >
      <div className="title-wrapper">
        <>
          <p className="title">{title}</p>
          {subTitle && <p className="sub-title">{subTitle}</p>}
        </>
      </div>
      {description && <p className="description">{description}</p>}
      {icon && (
        <div className="icon-wrapper">
          <img src={icon} alt={title} />
        </div>
      )}
    </div>
  );
};

export default LinkItem;
