import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import BackButtonHeader from '~components/backButtonHeader/backButtonHeader';
import '~shared/styles/webView.scss';
import '~shared/styles/titleHeader.scss';
import '~shared/styles/footer.scss';
import '~shared/styles/activateSubscriptionView.scss';
import PriceInfo from '~components/priceInfo/priceInfo';
import { ISubscription } from '~interfaces/subscriptions';
import TCSenseCar from '~components/subscriptionDetails/icons/TCSenseCar.svg';
import TCInfo from '~components/subscriptionDetails/icons/TCInfo.svg';
import TCWireless from '~components/subscriptionDetails/icons/TCWireless.svg';
import TCInvoice from '~components/subscriptionDetails/icons/TCInvoice.svg';
import { Checkmark, AlertFilled, Canceled } from '~shared/svg-icons/index';
import Text from '~components/text/text';
import { Store } from '../store';

interface IProps {
  subscription: ISubscription;
}

const ActivateSubscriptionView = ({
  location: { state },
}: {
  location: {
    state: IProps;
  };
}) => {
  const { subscription } = state;
  const {
    state: { user },
  } = useContext(Store);
  const [userAccepted, setUserAccepted] = useState(false);

  const costToUpgrade = subscription.isBasic
    ? subscription.premiumSubscription?.price
    : subscription.price;

  const description = `${subscription.isBasic ? intl.get('UPGRADE') : intl.get('ACTIVATE')}
  ${subscription.licensePlate.toUpperCase()}`;

  return (
    <div className="web-view-container activate-subscription-view">
      <div className="row">
        <BackButtonHeader
          title={`${subscription.licensePlate.toUpperCase()}`}
          to="/account/subscription"
        />
      </div>
      <section className="row activate-section-wrapper">
        <h2 className="activate-subscription-title">
          {description}
          ?
        </h2>
        <div className="activate-subscription-container">
          <div className="grid-item">
            <div className="icon-wrapper">
              {['active'].includes(subscription.status) && <Checkmark />}
              {['canceled', 'paused'].includes(subscription.status) && <AlertFilled />}
              {['complete', 'payment_failed'].includes(subscription.status) && <Canceled />}
            </div>
            <div className="content-wrapper">
              <Text bold>{`${subscription.title} ${subscription.description}`}</Text>
            </div>
          </div>
          <div className="subscription-container">
            {subscription.price !== 0
              && subscription.freeMonths > -1
              && subscription.usedMonths >= subscription.freeMonths && (
                <div className="subscription-item-container subscription-item-container--large">
                  <img src={TCInvoice} className="subscription-icon-wrapper--large" alt="Price" />
                  <p className="subscription-item-price-text">{`${subscription.price} ${subscription.currency}`}</p>
                </div>
            )}
            {subscription.licensePlate && (
              <div className="subscription-item-container subscription-item-container--large">
                <img src={TCSenseCar} className="subscription-icon-wrapper--large" alt="Car" />
                <p className="subscription-item-text">{`${subscription.licensePlate.toUpperCase()}`}</p>
              </div>
            )}
            {subscription.imei && (
              <div className="subscription-item-container subscription-item-container--large">
                <img src={TCInfo} className="subscription-icon-wrapper--large" alt="IMEI" />
                <p className="subscription-item-text">{`${subscription.imei}`}</p>
              </div>
            )}
            {subscription.status && (
              <div className="subscription-item-container subscription-item-container--large">
                <img src={TCWireless} className="subscription-icon-wrapper--large" alt="WIFI" />
                {subscription.isPaydrive && (
                  <p className="subscription-item-text">
                    {subscription.isPremium && subscription.wifiEnabled
                      ? intl.get('ENABLED')
                      : intl.get('DISABLED')}
                  </p>
                )}
                {!subscription.isPaydrive && (
                  <p className="subscription-item-text">
                    {subscription.wifiEnabled ? intl.get('ENABLED') : intl.get('DISABLED')}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
      <div className="row subscription-footer">
        <div className="row checkbox-container">
          <div className="row">
            <label htmlFor="termsCheckbox" className="checkbox">
              <span>
                {intl.get('I_ACCEPT')}
                {' '}
                <a href={intl.get('TOC_URL')} target="_blank" rel="noopener noreferrer">
                  {intl.get('TELIA_TOC')}
                </a>
              </span>
              <input
                id="termsCheckbox"
                name="termsCheckbox"
                type="checkbox"
                onChange={(event) => setUserAccepted(event.target.checked)}
              />
              <span className="checkmark" />
            </label>
          </div>
        </div>
        <div className="row">
          <PriceInfo price={costToUpgrade} unitString={subscription.currency} />
          {subscription.status === 'canceled' && (
            <div className="next-billing-info">
              <p>{intl.get('NEXT_BILLING_DATE')}</p>
              <p>{subscription.nextRun.slice(0, 10)}</p>
            </div>
          )}
          {user?.cardRefLabel ? (
            <>
              <div className="saved-card-info">
                <p>{intl.get('ACCOUNT__SAVED_PAYMENT_METHOD')}</p>
                <p>{user?.cardRefLabel}</p>
              </div>
              <div className="saved-card-info">
                <Link to="/account/payment/edit">{intl.get('EDIT')}</Link>
              </div>
            </>
          ) : (
            <div className="saved-card-info">
              <p>{intl.get('PAYMENT_PROFILE__NO_SAVED_CREDITCARD')}</p>
              <Link to="/account/payment/edit">{intl.get('EDIT')}</Link>
            </div>
          )}
        </div>
        {userAccepted && user?.cardRefLabel ? (
          <Link
            className="button no-border green"
            to={{
              pathname: '/account/subscription/activate/processing',
              state: { subscription },
            }}
          >
            {description}
          </Link>
        ) : (
          <button type="button" disabled className="button no-border gray">
            {description}
          </button>
        )}
      </div>
    </div>
  );
};

export default ActivateSubscriptionView;
