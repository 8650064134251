import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AccountView from './containers/accountView';
import ActivateSubscriptionView from '~containers/activateSubscriptionView';
import AddressEditView from './containers/addressEditView';
import ProfileView from './containers/profileView';
import ProfileEditView from './containers/profileEditView';
import SubscriptionView from './containers/subscriptionView';
import PaymentProfileView from './containers/paymentProfileView';
import PaymentEditView from './containers/paymentEditView';
import ReceiptView from './containers/receiptView';
import RegistrationView from './containers/registrationView';
import RemoveSubscriptionView from '~containers/removeSubscriptionView';
import RemoveAccountView from '~containers/removeAccountView';
import ProcessingRegistrationView from './containers/processingRegistrationView';
import ProcessingPaymentView from './containers/processingPaymentView';
import ProcessingProfileEditView from './containers/processingProfileEditView';
import ProcessingRemoveAccountView from '~containers/processingRemoveAccountView';
import ProcessingRemoveSubscriptionView from '~containers/processingRemoveSubscriptionView';
import ProcessingActivateSubscriptionView from '~containers/processingActivateSubscriptionView';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={() => <Redirect to="/account" />} />
    <Route path="/registration" exact component={RegistrationView} />
    <Route path="/registration/processing" exact component={ProcessingRegistrationView} />
    <Route path="/account" exact component={AccountView} />
    <Route path="/account/profile" exact component={ProfileView} />
    <Route path="/account/profile/address/edit" exact component={AddressEditView} />
    <Route path="/account/profile/edit" exact component={ProfileEditView} />
    <Route path="/account/profile/processing" exact component={ProcessingProfileEditView} />
    <Route path="/account/profile/remove" exact component={RemoveAccountView} />
    <Route
      path="/account/profile/remove/processing"
      exact
      component={ProcessingRemoveAccountView}
    />
    <Route path="/account/subscription" exact component={SubscriptionView} />
    <Route path="/account/subscription/remove" exact component={RemoveSubscriptionView} />
    <Route path="/account/subscription/remove/processing" exact component={ProcessingRemoveSubscriptionView} />
    <Route path="/account/subscription/activate" exact component={ActivateSubscriptionView} />
    <Route path="/account/subscription/activate/processing" exact component={ProcessingActivateSubscriptionView} />
    <Route path="/account/payment" exact component={PaymentProfileView} />
    <Route path="/account/payment/edit" exact component={PaymentEditView} />
    <Route path="/account/payment/processing" exact component={ProcessingPaymentView} />
    <Route path="/account/payment/receipt/:id" exact component={ReceiptView} />
  </Switch>
);

export default Routes;
