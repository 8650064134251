import React from 'react';
import Text from '~components/text/text';
import '~shared/styles/footer.scss';

interface IProps {
  disabled?: boolean;
  text: string;
}

const FooterText = ({ text, disabled }: IProps) => (
  <div className="footer-section">
    {!disabled && (
      <Text color="gray" size="xsmall">
        {text}
      </Text>
    )}
  </div>
);

export default FooterText;
