import React, { useContext } from 'react';
import intl from 'react-intl-universal';
import ListItem from '~components/listItem/listItem';
import ButtonNavigationHeader from '~components/buttonNavigationHeader/buttonNavigationHeader';
import { Store } from '../store';
import TC_Pen from '~shared/svg-icons/TC_Pen.svg';
import '~components/AdyenStyledForm/AdyenStyledForm.scss';
import '~shared/styles/webView.scss';
import '~shared/styles/profileView.scss';
import '~shared/styles/titleHeader.scss';
import '~shared/styles/footer.scss';
import LinkItem from '~components/linkItem/linkItem';

const ProfileView = () => {
  const {
    state: { user },
  } = useContext(Store);

  return (
    <div className="web-view-container profile-view">
      <div className="row">
        <ButtonNavigationHeader
          title={intl.get('PROFILE')}
          previous={{ pathname: '/account' }}
          to={{
            pathname: '/account/profile/edit',
            description: intl.get('EDIT'),
            icon: TC_Pen,
            active: true,
          }}
        />
      </div>
      <div className="row section-wrapper profile-section">
        <h2 className="section-title">{intl.get('CONTACT_INFORMATION')}</h2>
        {user && (
          <>
            <ListItem
              title={intl.get('NAME')}
              description={`${user.givenName} ${user.familyName}`}
            />
            <ListItem title={intl.get('EMAIL')} description={user.email} />
          </>
        )}
      </div>
      <div className="row profile-footer">
        {!user?.getSubscriptionsThrewError && (
          <LinkItem
            title={intl.get('ACCOUNT__REMOVE')}
            to={{ pathname: '/account/profile/remove' }}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileView;
