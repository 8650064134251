import React, { useContext, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import Confirmation from '~components/confirmation/confirmation';
import Processing from '~components/processing/processing';
import { Api } from '../repositories/api';
import { Store } from '../store';
import { ISubscription } from '~interfaces/subscriptions';

interface ISubscriptionData {
  subscription: ISubscription;
}

enum ProcessingStatus {
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

interface ProcessingState {
  status: ProcessingStatus;
  reason?: string;
}

enum OperationStatus {
  ASLEEP = 'ASLEEP',
  INITIALIZED = 'INITIALIZED',
  PENDING = 'PENDING',
  UPDATING = 'UPDATING',
  REDIRECT = 'REDIRECT',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
}

interface OperationState {
  status: OperationStatus;
  reason?: string;
  retries?: number;
  type?: string;
}

const ProcessingActivateSubscriptionView = ({
  location: { state },
}: {
  location: {
    state?: ISubscriptionData;
  };
}) => {
  const {
    dispatch,
    state: { user, setUserDataValue },
  } = useContext(Store);

  const [processingState, setProcessingState] = useState(() => {
    const status = ProcessingStatus.PENDING;
    return {
      status,
    } as ProcessingState;
  });

  const [activateSubscriptionState, setActivateSubscriptionState] = useState(() => {
    const status = OperationStatus.ASLEEP;
    return {
      status,
    } as OperationState;
  });

  const [subscriptionState, setSubscriptionState] = useState(() => {
    const status = OperationStatus.ASLEEP;
    return {
      status,
    } as OperationState;
  });

  const [checkOrdersState, setCheckOrdersState] = useState({
    status: OperationStatus.ASLEEP,
  } as OperationState);

  const [pollStatusState, setPollStatusState] = useState(() => {
    const status = OperationStatus.ASLEEP;
    const retries = 0;
    return {
      status,
      retries,
    } as OperationState;
  });

  const [jobUuid, setJobUuid] = useState('');
  const [subscription, setSubscription] = useState(state?.subscription);

  useEffect(() => {
    if (subscription?.isPaydrive) {
      setSubscription(
        state!.subscription?.isBasic
          ? state!.subscription?.premiumSubscription
          : state!.subscription,
      );
    }
    setSubscriptionState({ status: OperationStatus.SUCCEEDED });
  }, []);

  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (subscriptionState.status === OperationStatus.ASLEEP) return;
    if (activateSubscriptionState.status !== OperationStatus.ASLEEP) return;

    setActivateSubscriptionState({ status: OperationStatus.PENDING });

    if (subscription?.status === 'payment_failed') {
      setCheckOrdersState({ status: OperationStatus.PENDING });
      Api.checkForUnpaidOrders(subscription?.id, user?.accessToken)
        .then((uuid) => {
          setCheckOrdersState({ status: OperationStatus.SUCCEEDED });
          setJobUuid(uuid);
        })
        .catch((err) => {
          /* eslint-disable-next-line no-console */
          console.error(err);
          setCheckOrdersState({ status: OperationStatus.FAILED });
          setProcessingState({
            status: ProcessingStatus.FAILED,
            reason: err,
          });
        });
    } else {
      Api.activateSubscription(subscription!.id, user?.accessToken)
        .then(() => {
          setActivateSubscriptionState({ status: OperationStatus.SUCCEEDED });
        })
        .catch((error) => {
          /* eslint-disable-next-line no-console */
          console.error(error);
          setActivateSubscriptionState({ status: OperationStatus.FAILED });
          setProcessingState({
            status: ProcessingStatus.FAILED,
            reason: 'Failed to activate subscription.',
          });
        });
    }
  }, [subscriptionState]);

  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (checkOrdersState.status !== OperationStatus.SUCCEEDED) return;
    if (
      pollStatusState.status === OperationStatus.SUCCEEDED
      || pollStatusState.status === OperationStatus.FAILED
    ) return;
    if (!jobUuid) return;

    Api.getOrderStatus(jobUuid, user?.accessToken)
      .then(({ status, error }) => {
        if (status === 'success') {
          setPollStatusState((prev) => ({ ...prev, status: OperationStatus.SUCCEEDED }));
          setActivateSubscriptionState({ status: OperationStatus.SUCCEEDED });
          setProcessingState({ status: ProcessingStatus.SUCCEEDED });
        } else if (status === 'pending') {
          setPollStatusState((prev) => ({ ...prev, status: OperationStatus.PENDING }));
          if (pollStatusState.retries! >= 5) {
            setPollStatusState((prev) => ({ ...prev, status: OperationStatus.FAILED }));
            setProcessingState({
              status: ProcessingStatus.FAILED,
              reason: 'Failed after five pollings',
            });
          } else {
            setTimeout(() => {
              setPollStatusState((prev) => ({
                ...prev,
                retries: prev.retries! + 1,
              }));
            }, 1000);
          }
        } else {
          setPollStatusState((prev) => ({ ...prev, status: OperationStatus.FAILED }));
          setProcessingState({ status: ProcessingStatus.FAILED, reason: error });
        }
      })
      .catch((err) => {
        /* eslint-disable-next-line no-console */
        console.error(err);
        setPollStatusState((prev) => ({ ...prev, status: OperationStatus.FAILED }));
        setProcessingState({
          status: ProcessingStatus.FAILED,
          reason:
            'Failed to reactivate subscriptions.\nPlease confirm you have the sufficient funds and add your payment information again.',
        });
      });
  }, [checkOrdersState, jobUuid, pollStatusState.retries]);

  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (activateSubscriptionState.status !== OperationStatus.SUCCEEDED) return;
    setProcessingState({ status: ProcessingStatus.SUCCEEDED });
    setUserDataValue('infoUpdated', true, dispatch);
  }, [processingState, activateSubscriptionState]);

  const successful = processingState.status === ProcessingStatus.SUCCEEDED;

  const heading = successful
    ? intl.get('SUBSCRIPTION_ACTIVATE__SUCCESS_HEADING')
    : intl.get('SUBSCRIPTION_ACTIVATE__ERROR_HEADING');

  const description = successful ? intl.get('SUBSCRIPTION_ACTIVATED') : processingState.reason!;

  const pathname = successful ? '/account' : '/account/subscription';

  const buttonText = successful
    ? intl.get('SUBSCRIPTION_ACTIVATE__SUCCESS_BUTTON')
    : intl.get('SUBSCRIPTION_ACTIVATE__ERROR_BUTTON');

  return (
    <div data-testid="processing-view" className="web-view-container">
      <div id="processing-view-content">
        {![ProcessingStatus.SUCCEEDED, ProcessingStatus.FAILED].includes(
          processingState.status,
        ) && <Processing altText="Activating subscription" />}
        {[ProcessingStatus.SUCCEEDED, ProcessingStatus.FAILED].includes(processingState.status) && (
          <Confirmation
            successful={successful}
            heading={heading}
            description={description}
            to={{ pathname }}
            buttonText={buttonText}
          />
        )}
      </div>
    </div>
  );
};

export default ProcessingActivateSubscriptionView;
