import React from 'react';
import { Link } from 'react-router-dom';
import added from './icons/added.svg';
import alert from './icons/alert.svg';
import './confirmation.scss';

interface IProps {
  successful: boolean;
  heading: string;
  description: string;
  buttonText: string;
  redirectIsDeepLink?: boolean;
  to: {
    pathname: string;
    state?: object;
  };
  onButtonClick?: { (): void };
}

const Confirmation = (props: IProps) => {
  const {
    successful,
    heading,
    description,
    buttonText,
    to,
    onButtonClick,
    redirectIsDeepLink,
  } = props;
  const icon = successful ? added : alert;

  return (
    <div className="confirmation-wrapper">
      <img src={icon} alt="Status logo" />
      <h1>{heading}</h1>
      <p>{description}</p>
      {redirectIsDeepLink ? (
        <a onClick={onButtonClick} className="button purple" href={to.pathname}>
          {buttonText}
        </a>
      ) : (
        <Link onClick={onButtonClick} className="button purple" to={to}>
          {buttonText}
        </Link>
      )}
    </div>
  );
};

export default Confirmation;
