import React, { useContext, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import Confirmation from '~components/confirmation/confirmation';
import Processing from '~components/processing/processing';
import { Api } from '../repositories/api';
import { Store } from '../store';
import { IProfileInformation } from '~interfaces/userData';

interface IProfileEditData {
  userInfo: IProfileInformation;
}

enum ProcessingStatus {
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

interface ProcessingState {
  status: ProcessingStatus;
  reason?: string;
}

enum OperationStatus {
  ASLEEP = 'ASLEEP',
  INITIALIZED = 'INITIALIZED',
  PENDING = 'PENDING',
  UPDATING = 'UPDATING',
  REDIRECT = 'REDIRECT',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
}

interface OperationState {
  status: OperationStatus;
  reason?: string;
  type?: string;
}

const ProcessingProfileEditView = ({
  location: { data },
}: {
  location: {
    data?: IProfileEditData;
  };
}) => {
  const { state, dispatch } = useContext(Store);
  const [processingState, setProcessingState] = useState(() => {
    const status = ProcessingStatus.PENDING;
    return {
      status,
    } as ProcessingState;
  });

  const [saveProfileInformationState, setSaveProfileInformationState] = useState(() => {
    const status = !data ? OperationStatus.SUCCEEDED : OperationStatus.ASLEEP;
    return {
      status,
    } as OperationState;
  });

  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (saveProfileInformationState.status !== OperationStatus.ASLEEP) return;

    setSaveProfileInformationState({ status: OperationStatus.PENDING });

    Api.setProfileInformation(data!.userInfo, state.user?.accessToken)
      .then((response) => {
        const { firstName, lastName, email } = response;
        state.setUserDataValue('givenName', firstName, dispatch);
        state.setUserDataValue('familyName', lastName, dispatch);
        state.setUserDataValue('email', email, dispatch);
        setSaveProfileInformationState({ status: OperationStatus.SUCCEEDED });
      })
      .catch((error) => {
        /* eslint-disable-next-line no-console */
        console.error(error);
        setSaveProfileInformationState({ status: OperationStatus.FAILED });
        setProcessingState({
          status: ProcessingStatus.FAILED,
          reason: 'Failed to save profile information',
        });
      });
  }, []);

  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (saveProfileInformationState.status !== OperationStatus.SUCCEEDED) return;
    setProcessingState({ status: ProcessingStatus.SUCCEEDED });
  }, [processingState, saveProfileInformationState]);

  const successful = processingState.status === ProcessingStatus.SUCCEEDED;

  const heading = successful
    ? intl.get('MANAGE_PROFILE__SUCCESS_HEADING')
    : intl.get('MANAGE_PROFILE__ERROR_DESCRIPTION');

  const description = successful ? 'Profile has been updated' : processingState.reason!;

  const pathname = successful ? '/account/profile' : '/account/profile/edit';

  const buttonText = successful
    ? intl.get('MANAGE_PAYMENT__SUCCESS_BUTTON')
    : intl.get('MANAGE_PAYMENT__ERROR_BUTTON');

  return (
    <div data-testid="processing-view" className="web-view-container">
      <div id="processing-view-content">
        {![ProcessingStatus.SUCCEEDED, ProcessingStatus.FAILED].includes(
          processingState.status,
        ) && <Processing altText="saving profile information" />}
        {[ProcessingStatus.SUCCEEDED, ProcessingStatus.FAILED].includes(processingState.status) && (
          <Confirmation
            successful={successful}
            heading={heading}
            description={description}
            to={{ pathname }}
            buttonText={buttonText}
          />
        )}
      </div>
    </div>
  );
};

export default ProcessingProfileEditView;
