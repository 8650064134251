import React from 'react';
import './listItem.scss';

interface IProps {
  title: string;
  description?: string;
  borderBottom?: boolean;
}

const ListItem = ({
  title, description, borderBottom,
}: IProps) => (
  <div className="list-item-container" style={borderBottom ? { borderBottom: 'none' } : undefined}>
    <div className="list-item-wrapper">
      <p className="title">{title}</p>
      {description && <p className="description">{description}</p>}
    </div>
  </div>
);

export default ListItem;
