import React from 'react';
import intl from 'react-intl-universal';
import './subscriptionDetails.scss';
import TCSenseCarNoSignal from './icons/TCSenseCarNoSignal.svg';
import TCSenseCar from './icons/TCSenseCar.svg';
import TCInfo from './icons/TCInfo.svg';
import TCWireless from './icons/TCWireless.svg';
import TCWirelessNoSignal from './icons/TCWirelessNoSignal.svg';
import TCSyncGreen from './icons/TCSyncGreen.svg';
import TCCloseRed from './icons/TCCloseRed.svg';
import TCInvoice from './icons/TCInvoice.svg';
import TCArrowUp from './icons/TCArrowUp.svg';
import { ISubscription } from '~interfaces/subscriptions';
import LinkItemRemove from '~components/linkItemRemove/linkItemRemove';
import Text from '../text/text';
import LinkItem from '~components/linkItem/linkItem';

/* eslint-disable */

interface ISubscriptionDetails {
  subscription: ISubscription;
  largeIcons?: boolean;
}

const SubscriptionDetails = ({ subscription, largeIcons }: ISubscriptionDetails) => {
  const classItemContainer = largeIcons
    ? 'subscription-item-container subscription-item-container--large'
    : 'subscription-item-container';
  const classIcon = largeIcons ? 'subscription-icon-wrapper--large' : 'subscription-icon-wrapper';
  const carIcon = subscription.status === 'active' ? TCSenseCar : TCSenseCarNoSignal;
  const wifiIcon = subscription.wifiEnabled ? TCWireless : TCWirelessNoSignal;

  const SubscriptionAction = () => {
    if (subscription.status === 'paused')
      return <div style={{ borderBottom: '1px solid #d2d2d2' }}></div>;
    if (subscription.status === 'active') {
      if (subscription.isBasic) {
        return (
          <LinkItem
            title={intl.get('SUBSCRIPTION__UPGRADE_TO_PREMIUM')}
            to={{ pathname: '/account/subscription/activate', state: { subscription } }}
            icon={TCArrowUp}
          />
        );
      }
      return (
        <LinkItemRemove
          title={intl.get('SUBSCRIPTION__CANCEL_SUBSCRIPTION')}
          to={{ pathname: '/account/subscription/remove', data: { subscription } }}
          icon={TCCloseRed}
        />
      );
    }
    return (
      <LinkItem
        title={intl.get('SUBSCRIPTION__ACTIVATE_SUBSCRIPTION')}
        to={{ pathname: '/account/subscription/activate', state: { subscription } }}
        icon={TCSyncGreen}
      />
    );
  };

  return (
    <div className="subscription-container">
      {subscription.price !== 0 &&
        subscription.freeMonths > -1 &&
        subscription.usedMonths >= subscription.freeMonths && (
          <div className={classItemContainer}>
            <img src={TCInvoice} className={classIcon} alt="Price" />
            <p className="subscription-item-price-text">{`${subscription.price} ${subscription.currency}`}</p>
          </div>
        )}
      {subscription.licensePlate && (
        <div className={classItemContainer}>
          <img src={carIcon} className={classIcon} alt="Car" />
          <p className="subscription-item-text">{`${subscription.licensePlate.toUpperCase()}`}</p>
        </div>
      )}
      {subscription.imei && (
        <div className={classItemContainer}>
          <img src={TCInfo} className={classIcon} alt="IMEI" />
          <p className="subscription-item-text">{`${subscription.imei}`}</p>
        </div>
      )}
      {subscription.status && (
        <div className={classItemContainer}>
          <img src={wifiIcon} className={classIcon} alt="WIFI" />
          {subscription.isPaydrive && (
            <p className="subscription-item-text">
              {subscription.isPremium && subscription.wifiEnabled
                ? intl.get('ENABLED')
                : intl.get('DISABLED')}
            </p>
          )}
          {!subscription.isPaydrive && (
            <p className="subscription-item-text">
              {subscription.wifiEnabled ? intl.get('ENABLED') : intl.get('DISABLED')}
            </p>
          )}
        </div>
      )}
      <div>
        <div className="content-wrapper">
          {subscription.price !== 0 &&
            subscription.freeMonths > -1 &&
            subscription.usedMonths >= subscription.freeMonths &&
            subscription.status === 'active' && (
              <div className="subscription-details-next-run">
                <Text bold>{intl.get('PAYMENT')}</Text>
                <Text size="small">
                  {intl.get('SUBSCRIPTION__NEXT_RUN', {
                    nextRun: subscription.nextRun.slice(0, 10),
                    price: subscription.price,
                    currency: subscription.currency,
                  })}
                </Text>
              </div>
            )}
          {subscription.status === 'complete' && (
            <div className="subscription-details-next-run">
              <Text bold>{intl.get('SUBSCRIPTION_REMOVE__SUCCESS_HEADING')}</Text>
              <Text size="small">{intl.get('SUBSCRIPTION_REMOVE__NO_LONGER_ACTIVE')}</Text>
            </div>
          )}
          {subscription.status === 'canceled' && (
            <div className="subscription-details-next-run">
              <Text bold>{intl.get('SUBSCRIPTION__CANCELED')}</Text>
              <Text size="small">
                {intl.get('SUBSCRIPTION__TERMINATION_DATE', {
                  terminationDate: subscription.nextRun.slice(0, 10),
                })}
              </Text>
            </div>
          )}
          {subscription.status === 'payment_failed' && (
            <div className="subscription-details-next-run">
              <Text bold>{intl.get('PAYMENT__FAILED')}</Text>
              <Text size="small">{intl.get('TO_CONTINUE_TO_USE_SERVICE')}</Text>
            </div>
          )}
          {subscription.status === 'paused' && (
            <div className="subscription-details-next-run">
              <Text bold>{intl.get('SUBSCRIPTION__PAUSED')}</Text>
              <Text size="small">{intl.get('TO_CONTINUE_TO_USE_SERVICE')}</Text>
              <Text size="small">{intl.get('ANOTHER_BILLING_ATTEMPT')}</Text>
            </div>
          )}
        </div>
      </div>
      {largeIcons && <SubscriptionAction />}
    </div>
  );
};

export default SubscriptionDetails;
