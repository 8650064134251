import React from 'react';

export const RadioIcon = () => (
  <svg className="radio-button-svg" fill="none" width="16px" height="16px" viewBox="0 0 20 20">
    <circle className="radio-button-frame" cx="10" cy="10" r="9" />
    <circle className="radio-button-check-mark" cx="10" cy="10" r="6" />
  </svg>
);

export const Check = () => (
  <svg className="checkmark" viewBox="0 0 512 512">
    <path
      d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0
      c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7
      C514.5,101.703,514.499,85.494,504.502,75.496z"
    />
  </svg>
);

export const Arrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.912 12.993">
    <defs>
      <clipPath id="clip-path">
        <path
          id="Path_647"
          data-name="Path 647"
          d="M15.088,13.912l-5.082,5.082L8.594,17.583,13.676,12.5,8.59,7.414,10,6l6.5,6.5L15.09,13.914Z"
          fill="#c7c7cc"
          clipRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <rect
          id="Rectangle_328"
          data-name="Rectangle 328"
          width="19"
          height="23"
          transform="translate(3 1)"
          fill="#c7c7cc"
        />
      </clipPath>
      <clipPath id="clip-path-3">
        <rect
          id="Rectangle_327"
          data-name="Rectangle 327"
          width="9"
          height="13"
          transform="translate(8 6)"
          fill="#c7c7cc"
        />
      </clipPath>
    </defs>
    <g
      id="Group_774"
      data-name="Group 774"
      transform="translate(-8.59 -6.002)"
      clipPath="url(#clip-path)"
    >
      <g id="Group_773" data-name="Group 773">
        <g id="Group_772" data-name="Group 772">
          <rect
            id="Rectangle_324"
            data-name="Rectangle 324"
            width="19"
            height="23"
            transform="translate(3 1)"
            fill="#c7c7cc"
          />
          <g id="Group_771" data-name="Group 771" clipPath="url(#clip-path-2)">
            <g id="Group_770" data-name="Group 770">
              <rect
                id="Rectangle_325"
                data-name="Rectangle 325"
                width="9"
                height="13"
                transform="translate(8 6)"
                fill="#c7c7cc"
              />
              <g id="Group_769" data-name="Group 769" clipPath="url(#clip-path-3)">
                <rect
                  id="Rectangle_326"
                  data-name="Rectangle 326"
                  width="17.912"
                  height="22.993"
                  transform="translate(3.59 1.002)"
                  fill="#c7c7cc"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ArrowLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <title>TC</title>
    <g id="ARTWORK">
      <path d="M56,32a2.0001,2.0001,0,0,1-2,2H14.82812L27.41406,46.58594a1.99978,1.99978,0,1,1-2.82812,2.82812l-16-16a1.99926,1.99926,0,0,1,0-2.82812l16-16a1.99978,1.99978,0,0,1,2.82812,2.82812L14.82812,30H54A2.0001,2.0001,0,0,1,56,32Z" />
    </g>
  </svg>
);

export const Checkmark = () => (
  <svg viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Active</title>
    <desc>Created with Sketch.</desc>
    <circle cx="100" cy="100" r="100" fill="#00d066" />
    <g
      id="Icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="scale(0.6)"
    >
      <g id="TC-tick-icon-valid" transform="translate(-590.000000, -590.000000)" fill="#FFFFFF">
        <path d="M820.700611,694.361929 L724.831849,789.438022 L683.379413,748.328326 C676.217265,741.225396 664.573772,741.225396 657.371612,748.328326 C650.209463,755.470937 650.209463,766.978478 657.371612,774.121089 L711.787937,828.087486 C715.228969,831.500066 719.950385,833.444444 724.791838,833.444444 C729.63329,833.444444 734.354706,831.500066 737.795739,828.087486 L846.628389,720.115011 C853.790537,713.012081 853.790537,701.464859 846.628389,694.322248 C839.506252,687.219318 827.862759,687.219318 820.700611,694.361929 L820.700611,694.361929 Z" />
      </g>
    </g>
  </svg>
);

export const CheckmarkFilled = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <circle r="12" cx="12" cy="12" fill="#fff" />
    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z" />
  </svg>
);

export const Alert = () => (
  <svg width="24" height="24" viewBox="0 0 62 55" version="1.1">
    <title>Inactive</title>
    <desc>Created with Sketch.</desc>
    <g id="WIP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Payment-Error" transform="translate(-157.000000, -128.000000)">
        <g id="TC_Alert" transform="translate(152.000000, 120.000000)">
          <path
            d="M65.545875,49.3695 L43.716375,12.990375 C42.0906689,10.2792647 39.1614954,8.61991964 36.0003186,8.61991964 C32.8391417,8.61991964 29.9097263,10.2783768 28.283625,12.98925 L6.454125,49.3695 C4.78587334,52.1498882 4.74218108,55.6127128 6.33974961,58.4343076 C7.93731813,61.2559024 10.9291534,63 14.171625,63 L57.828375,63 C61.0708466,63 64.0626819,61.2559024 65.6602504,58.4343076 C67.2578189,55.6127128 67.2141267,52.1498882 65.545875,49.3695 Z M61.743375,56.217375 C60.9528548,57.635276 59.4528494,58.5100995 57.8295,58.5000865 L14.171625,58.5000865 C12.5503892,58.5000865 11.0544716,57.6279512 10.2556873,56.2171538 C9.45690304,54.8063564 9.47874917,53.0749441 10.312875,51.68475 L32.14125,15.3045 C32.9540727,13.948472 34.4190216,13.1186154 36,13.1186154 C37.5809784,13.1186154 39.0459273,13.948472 39.85875,15.3045 L61.687125,51.68475 C62.5320353,53.072032 62.553598,54.8095527 61.743375,56.217375 Z M33.7499954,41.599125 L33.7499954,28.301625 C33.7491327,27.6972021 33.8700377,27.0987985 34.1055,26.542125 C34.2981865,26.0432592 34.5985777,25.5930566 34.98525,25.223625 C35.2575317,24.9419222 35.6257601,24.7728664 36.016875,24.75 C36.581625,24.75 37.145625,25.17825 37.708875,26.03475 C38.0147156,26.4120657 38.2028912,26.8709638 38.2499954,27.354375 L38.2499954,40.651875 C38.2499954,41.147625 37.90575,41.62125 37.21725,42.07275 C36.5628318,42.5131205 35.7920397,42.7485097 35.00325,42.748875 C34.16775,42.748875 33.7499954,42.365625 33.7499954,41.599125 Z M37.87875,47.89125 C38.223899,48.333325 38.4459142,48.8588644 38.52225,49.4145 C38.52225,49.977 38.13825,50.50725 37.37025,51.00525 C36.6379303,51.491096 35.7785779,51.750154 34.89975,51.7500001 C33.95175,51.7500001 33.4777418,51.321375 33.4777418,50.464125 C33.4765427,49.7958098 33.6092812,49.1340296 33.868125,48.517875 C34.0834913,47.9608353 34.4183505,47.4577785 34.849125,47.044125 C35.1659735,46.7171432 35.5957768,46.5235707 36.050625,46.503 C36.637875,46.503 37.24725,46.96575 37.87875,47.89125 Z"
            id="Shape"
            fill="#FFD06B"
            fillRule="nonzero"
          />
          <polygon id="Path" points="0 0 72 0 72 72 0 72" />
        </g>
      </g>
    </g>
  </svg>
);
export const AlertFilled = () => (
  <svg width="25" height="25" viewBox="0 0 25 26">
    <g id="Group_3" transform="translate(-809.323 -470)">
      <rect
        id="AlertFilled_Rectangle"
        width="25"
        height="25"
        transform="translate(810 470)"
        fill="#fff"
      />
      <g id="AlertFilled" transform="translate(809.323 471)">
        <path
          id="AlertFilled"
          d="M20.85,22.543H3.718A4,4,0,0,1,.251,16.548L8.817,1.666a4,4,0,0,1,6.934,0l8.566,14.882a4,4,0,0,1-3.467,5.995Zm-8.331-6.607a1.652,1.652,0,1,0,1.642,1.652A1.649,1.649,0,0,0,12.519,15.936Zm0-8.5a1.644,1.644,0,0,0-1.643,1.642v3.794a1.643,1.643,0,0,0,3.285,0V9.084A1.644,1.644,0,0,0,12.519,7.441Z"
          transform="translate(0.893 1.058)"
          fill="#FFCA58"
        />
      </g>
    </g>
  </svg>
);

export const Canceled = () => (
  <svg width="25" height="25" viewBox="0 0 25 25">
    <g id="Group_4" data-name="Group 4" transform="translate(-754 -470)">
      <path
        id="Canceled"
        d="M-32.5,14a12.418,12.418,0,0,1-8.839-3.661A12.418,12.418,0,0,1-45,1.5a12.418,12.418,0,0,1,3.661-8.839A12.418,12.418,0,0,1-32.5-11a12.418,12.418,0,0,1,8.839,3.661A12.418,12.418,0,0,1-20,1.5a12.418,12.418,0,0,1-3.661,8.839A12.418,12.418,0,0,1-32.5,14Zm-.183-9.178A2.234,2.234,0,0,0-34.907,7.06,2.234,2.234,0,0,0-32.683,9.3,2.234,2.234,0,0,0-30.458,7.06,2.234,2.234,0,0,0-32.683,4.822Zm0-11.51a2.227,2.227,0,0,0-2.225,2.225V.679A2.227,2.227,0,0,0-32.683,2.9,2.227,2.227,0,0,0-30.458.679V-4.463A2.227,2.227,0,0,0-32.683-6.688Z"
        transform="translate(799 481)"
        fill="#c90c0c"
      />
    </g>
  </svg>
);

export const InvoiceIcon = () => (
  <svg className="invoice-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      fill="#222"
      d="M48 4H20a8.01 8.01 0 0 0-8 8v44a4.004 4.004 0 0 0 4 4h28a8.01 8.01 0 0 0 8-8V8a4.004 4.004 0 0 0-4-4zm0 48a4.004 4.004 0 0 1-4 4l-28 .002V12a4.004 4.004 0 0 1 4-4h28zM22 40a2 2 0 0 1 2-2h16a2 2 0 0 1 0 4H24a2 2 0 0 1-2-2zm0-8a2 2 0 0 1 2-2h8a2 2 0 0 1 0 4h-8a2 2 0 0 1-2-2zm20 16a2 2 0 0 1-2 2h-4a2 2 0 0 1 0-4h4a2 2 0 0 1 2 2zm-3.41-14.59A1.965 1.965 0 0 1 38 32a1.706 1.706 0 0 1 .04-.39 1.796 1.796 0 0 1 .11-.38 2.329 2.329 0 0 1 .19-.34 1.445 1.445 0 0 1 .25-.3 1.993 1.993 0 0 1 1.8-.55 1.798 1.798 0 0 1 .38.11 1.602 1.602 0 0 1 .34.19 2.147 2.147 0 0 1 .55.55 2.323 2.323 0 0 1 .19.34 1.86 1.86 0 0 1 .11.38A1.706 1.706 0 0 1 42 32a1.965 1.965 0 0 1-.59 1.41 1.432 1.432 0 0 1-.3.25 2.188 2.188 0 0 1-.34.19 2.716 2.716 0 0 1-.38.11A1.731 1.731 0 0 1 40 34a1.965 1.965 0 0 1-1.41-.59zM22 24a2 2 0 0 1 2-2h8a2 2 0 0 1 0 4h-8a2 2 0 0 1-2-2zm16.04.39a1.918 1.918 0 0 1 0-.78 1.638 1.638 0 0 1 .11-.38 2.329 2.329 0 0 1 .19-.34 1.833 1.833 0 0 1 .25-.31 2.015 2.015 0 0 1 1.8-.54 2.649 2.649 0 0 1 .38.11 1.602 1.602 0 0 1 .34.19 1.778 1.778 0 0 1 .3.24 1.833 1.833 0 0 1 .25.31 2.323 2.323 0 0 1 .19.34 2.002 2.002 0 0 1 .11 1.16 1.864 1.864 0 0 1-.11.38 2.318 2.318 0 0 1-.19.34 1.445 1.445 0 0 1-.25.3 1.98 1.98 0 0 1-2.82 0 1.445 1.445 0 0 1-.25-.3 2.324 2.324 0 0 1-.19-.34 1.8 1.8 0 0 1-.11-.38zM22 16a2 2 0 0 1 2-2h8a2 2 0 0 1 0 4h-8a2 2 0 0 1-2-2zm16.04.39a1.918 1.918 0 0 1 0-.78 1.796 1.796 0 0 1 .11-.38 2.329 2.329 0 0 1 .19-.34 1.445 1.445 0 0 1 .25-.3 1.993 1.993 0 0 1 1.8-.55 2.649 2.649 0 0 1 .38.11 2.23 2.23 0 0 1 .34.19 2.147 2.147 0 0 1 .55.55 2.323 2.323 0 0 1 .19.34 1.86 1.86 0 0 1 .11.38 1.918 1.918 0 0 1 0 .78 1.864 1.864 0 0 1-.11.38 2.318 2.318 0 0 1-.19.34 1.832 1.832 0 0 1-.25.31 1.777 1.777 0 0 1-.3.24 1.601 1.601 0 0 1-.34.19 1.8 1.8 0 0 1-.38.11A1.731 1.731 0 0 1 40 18a1.998 1.998 0 0 1-1.41-.58 1.832 1.832 0 0 1-.25-.31 2.324 2.324 0 0 1-.19-.34 1.8 1.8 0 0 1-.11-.38z"
    />
    <path fill="none" d="M0 0h64v64H0z" data-name="White space" />
  </svg>
);
