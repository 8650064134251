import React, { useContext, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import ScrollToTop from '~components/scrollToTop/scrollToTop';
import LinkItem from '~components/linkItem/linkItem';
import ListItem from '~components/listItem/listItem';
import { Store } from '../store';
import '~shared/styles/webView.scss';
import '~shared/styles/titleHeader.scss';
import '~shared/styles/footer.scss';
import '~shared/styles/errorMessage.scss';
import { transform } from '~services/utils';
import { Api } from '~repositories/api';
import { ISubscription } from '~interfaces/subscriptions';
import ProcessingSmall from '~components/processingSmall/processingSmall';
import SubscriptionListItem from '~components/subscriptionListItem/subscriptionListItem';

enum ProcessingState {
  ASLEEP = 'asleep',
  PENDING = 'pending',
  FAILED = 'failed',
  SUCCEEDED = 'succeeded',
}

const AccountView = () => {
  const { state, dispatch } = useContext(Store);

  const [subscriptionState, setSubscriptionState] = useState(
    !state.user?.subscriptions ? ProcessingState.ASLEEP : ProcessingState.SUCCEEDED,
  );
  const [creditCardRefState, setCreditCardRefState] = useState(
    !state.user?.cardReferences ? ProcessingState.ASLEEP : ProcessingState.SUCCEEDED,
  );

  useEffect(() => {
    Api.getBillingAddress(state.user?.accessToken)
      .then((userInfo) => {
        if (userInfo.firstName !== '-' || userInfo.lastName !== '-') {
          state.setUserDataValue('givenName', userInfo.firstName, dispatch);
          state.setUserDataValue('familyName', userInfo.lastName, dispatch);
        }
        state.setUserDataValue('email', userInfo.email, dispatch);
      }).catch((error) => {
        /* eslint-disable-next-line no-console */
        console.log(error);
      });
  }, [state.user?.accessToken]);

  useEffect(() => {
    if (creditCardRefState === ProcessingState.ASLEEP) {
      setCreditCardRefState(ProcessingState.PENDING);
      Api.getCardReferences(state.user?.accessToken)
        .then((response) => {
          const { cardReferences } = response;
          if (cardReferences.length > 0) {
            const cardRef = cardReferences[0];
            const cardInfo = `${transform.renameCardNetwork(cardRef.cardNetwork)} ${
              cardRef.PANTruncation
            } - ${cardRef.expiryMonth}/${cardRef.expiryYear.slice(2)}`;

            state.setUserDataValue('cardRefLabel', cardInfo, dispatch);
            state.setUserDataValue('cardReferences', cardReferences, dispatch);
          }
          setCreditCardRefState(ProcessingState.SUCCEEDED);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          setCreditCardRefState(ProcessingState.FAILED);
        });
    }
  }, [creditCardRefState]);

  useEffect(() => {
    if (subscriptionState === ProcessingState.ASLEEP || state.user?.infoUpdated) {
      setSubscriptionState(ProcessingState.PENDING);
      Api.getUserSubscriptions(state.user?.accessToken)
        .then((response) => {
          const { subscriptions } = response;
          state.setUserDataValue('subscriptions', subscriptions, dispatch);
          setSubscriptionState(ProcessingState.SUCCEEDED);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          state.setUserDataValue('getSubscriptionsThrewError', true, dispatch);
          setSubscriptionState(ProcessingState.FAILED);
        })
        .finally(() => {
          state.setUserDataValue('infoUpdated', false, dispatch);
        });
    }
  }, [subscriptionState, state.user?.infoUpdated]);

  const subscriptions: ISubscription[] = state.user?.subscriptions ?? [];

  const activeSubs = subscriptions.filter((sub: ISubscription) => {
    if (sub.isPaydrive) {
      return sub.status !== 'complete' || sub.premiumSubscription?.status !== 'complete';
    }
    return sub.status !== 'complete';
  });

  const CardReferences = () => {
    if (creditCardRefState === ProcessingState.PENDING) {
      return <ProcessingSmall altText="loading saved cards" />;
    }
    if (creditCardRefState === ProcessingState.SUCCEEDED) {
      return (
        <>
          {state.user?.cardReferences && (
            <ListItem
              title={intl.get('ACCOUNT__SAVED_PAYMENT_METHOD')}
              description={state.user?.cardRefLabel}
            />
          )}
          <LinkItem
            title={intl.get('ACCOUNT__MANAGE_PAYMENT')}
            to={{ pathname: '/account/payment' }}
          />
        </>
      );
    }
    return (
      <div className="error-message-container">
        <p>{intl.get('ACCOUNT__ERROR_CREDITCARD')}</p>
      </div>
    );
  };

  const SubscriptionsList = () => {
    if (subscriptionState === ProcessingState.PENDING) {
      return <ProcessingSmall altText="loading subscriptions" />;
    }
    if (subscriptionState === ProcessingState.FAILED) {
      return (
        <div className="error-message-container">
          <p>{intl.get('ACCOUNT__ERROR_SUBSCRIPTION')}</p>
        </div>
      );
    }

    return (
      <div>
        {activeSubs.map((subscription: any) => {
          if (subscription.isPaydrive) {
            if (subscription.premiumSubscription?.status !== 'complete') {
              return (
                <SubscriptionListItem
                  key={subscription.premiumSubscription.id}
                  title={subscription.premiumSubscription.title}
                  subscription={subscription.premiumSubscription}
                />
              );
            }
          }
          return (
            <SubscriptionListItem
              key={subscription.id}
              title={subscription.title}
              subscription={subscription}
            />
          );
        })}
        <LinkItem
          title={intl.get('ACCOUNT__MANAGE_SUBSCRIPTION')}
          to={{ pathname: '/account/subscription' }}
        />
      </div>
    );
  };

  return (
    <div className="web-view-container">
      <ScrollToTop />
      <div className="row">
        <div id="title-header" className="purple">
          <h2>{intl.get('ACCOUNT')}</h2>
        </div>
      </div>
      <div className="row profile-wrapper">
        <LinkItem
          title={state.user ? `${state.user.givenName} ${state.user.familyName}` : ''}
          subTitle={intl.get('ACCOUNT__EDIT_PROFILE')}
          to={{ pathname: '/account/profile' }}
        />
      </div>
      <section className="row section-wrapper">
        <h2 className="section-title">{intl.get('SUBSCRIPTIONS')}</h2>
        <SubscriptionsList />
      </section>
      <section className="row section-wrapper">
        <h2 className="section-title">{intl.get('PAYMENT')}</h2>
        <CardReferences />
      </section>
    </div>
  );
};

export default AccountView;
