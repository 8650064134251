import React, { useContext, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import Confirmation from '~components/confirmation/confirmation';
import Processing from '~components/processing/processing';
import { Api } from '../repositories/api';
import { Store } from '../store';

enum ProcessingStatus {
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

interface ProcessingState {
  status: ProcessingStatus;
  reason?: string;
}

enum OperationStatus {
  ASLEEP = 'ASLEEP',
  INITIALIZED = 'INITIALIZED',
  PENDING = 'PENDING',
  UPDATING = 'UPDATING',
  REDIRECT = 'REDIRECT',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
}

interface OperationState {
  status: OperationStatus;
  reason?: string;
  type?: string;
}

const ProcessingRemoveAccountView = () => {
  const { state } = useContext(Store);
  const [processingState, setProcessingState] = useState(() => {
    const status = ProcessingStatus.PENDING;
    return {
      status,
    } as ProcessingState;
  });

  const [removeAccountState, setRemoveAccountState] = useState(() => {
    const status = OperationStatus.PENDING;
    return {
      status,
    } as OperationState;
  });

  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    Api.removeAccount(state.user?.accessToken)
      .then(() => {
        setRemoveAccountState({ status: OperationStatus.SUCCEEDED });
      })
      .catch((error) => {
        /* eslint-disable-next-line no-console */
        console.error(error);
        setRemoveAccountState({ status: OperationStatus.FAILED });
        setProcessingState({
          status: ProcessingStatus.FAILED,
          reason: 'Failed to remove account information',
        });
      });
  }, []);

  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (removeAccountState.status !== OperationStatus.SUCCEEDED) return;
    setProcessingState({ status: ProcessingStatus.SUCCEEDED });
  }, [processingState, removeAccountState]);

  const successful = processingState.status === ProcessingStatus.SUCCEEDED;

  const heading = successful
    ? intl.get('ACCOUNT__REMOVE_SUCCESS_HEADING')
    : intl.get('ACCOUNT__REMOVE_ERROR_HEADING');

  const description = successful
    ? intl.get('ACCOUNT__REMOVE_SUCCESS_DESCRIPTION')
    : processingState.reason!;

  const pathname = successful
    ? 'teliasense://deep/activity_feed'
    : '/account/profile/remove';

  const buttonText = successful
    ? intl.get('ACCOUNT__REMOVE_SUCCESS_BUTTON')
    : intl.get('ACCOUNT__REMOVE_ERROR_BUTTON');

  return (
    <div data-testid="processing-view" className="web-view-container">
      <div id="processing-view-content">
        {![ProcessingStatus.SUCCEEDED, ProcessingStatus.FAILED].includes(
          processingState.status,
        ) && <Processing altText="removing account" />}
        {[ProcessingStatus.SUCCEEDED, ProcessingStatus.FAILED].includes(processingState.status) && (
          <Confirmation
            successful={successful}
            heading={heading}
            description={description}
            to={{ pathname }}
            buttonText={buttonText}
            redirectIsDeepLink={successful}
          />
        )}
      </div>
    </div>
  );
};

export default ProcessingRemoveAccountView;
