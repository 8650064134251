import React, { createContext, useReducer } from 'react';
import { userReducer, userContext } from './contexts/userContext';
import { IContextProps } from './interfaces/stateContext';

export const Store = createContext({} as IContextProps);

interface IProps {
  children: JSX.Element[] | JSX.Element;
}

export const StoreProvider = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(userReducer, userContext);
  const value: IContextProps = { state, dispatch };
  return <Store.Provider value={value}>{children}</Store.Provider>;
};
