import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import BackButtonHeader from '~components/backButtonHeader/backButtonHeader';
import { Store } from '../store';
import { ISubscription } from '~interfaces/subscriptions';
import '~shared/styles/webView.scss';
import '~shared/styles/titleHeader.scss';
import '~shared/styles/footer.scss';
import '~shared/styles/removeSubscriptionView.scss';
import LinkItem from '~components/linkItem/linkItem';

const RemoveAccountView = () => {
  const {
    state: { user },
  } = useContext(Store);

  const [activeSubs, setActiveSubs] = useState<ISubscription[]>([]);

  useEffect(() => {
    if (user?.subscriptions) {
      setActiveSubs(
        user.subscriptions.filter((sub: ISubscription) => {
          if (sub.isPaydrive && sub.premiumSubscription) {
            return (
              !['complete'].includes(sub.status)
              && !['complete'].includes(sub.premiumSubscription.status)
            );
          }
          return !['complete', 'canceled'].includes(sub.status);
        }),
      );
    }
  }, [user]);

  const RemoveAccountSection = () => {
    const [userAccepted, setUserAccepted] = useState(false);

    return (
      <>
        <section className="row remove-section-wrapper">
          <h2 className="remove-subscription-title">{intl.get('ACCOUNT__REMOVE_ARE_YOU_SURE')}</h2>
        </section>
        <div className="row remove-account-footer">
          <div className="row checkbox-container">
            <label htmlFor="acceptRemoveCheckbox" className="checkbox">
              <p>{intl.get('ACCOUNT__REMOVE_I_UNDERSTAND')}</p>
              <input
                id="acceptRemoveCheckbox"
                name="acceptRemoveCheckbox"
                type="checkbox"
                onChange={(event) => setUserAccepted(event.target.checked)}
              />
              <span className="checkmark" />
            </label>
          </div>
          <div className="row footer">
            {userAccepted ? (
              <Link
                className="button no-border red"
                to={{
                  pathname: '/account/profile/remove/processing',
                }}
              >
                {intl.get('ACCOUNT__REMOVE')}
              </Link>
            ) : (
              <button type="button" disabled className="button no-border gray">
                {intl.get('ACCOUNT__REMOVE')}
              </button>
            )}
          </div>
        </div>
      </>
    );
  };

  const SubscriptionsRemainSection = () => (
    <>
      <section className="row remove-section-wrapper">
        <div className="remove-subscription-error-wrapper">
          <h2 className="remove-subscription-title">
            {intl.get('ACCOUNT__REMOVE_REMAINING_SUBSCRIPTIONS')}
          </h2>
          <p className="remove-subscription-description">
            {intl.get('ACCOUNT__REMOVE_BUTTON_DESCRIPTION')}
          </p>
        </div>
      </section>
      <div className="row no-button footer remove-account-footer">
        <p className="subscription-cancel-basic">{intl.get('TELIA_BASIC_SUBSCRIPTION_CANCEL')}</p>
        <LinkItem
          title={intl.get('SUBSCRIPTION__MANAGE_SUBSCRIPTION')}
          to={{ pathname: '/account/subscription' }}
        />
      </div>
    </>
  );

  return (
    <div className="web-view-container remove-subscription-view">
      <div className="row">
        <BackButtonHeader title={intl.get('ACCOUNT__REMOVE')} to="/account/profile" />
      </div>
      {activeSubs.length ? <SubscriptionsRemainSection /> : <RemoveAccountSection />}
    </div>
  );
};

export default RemoveAccountView;
