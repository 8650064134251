import React from 'react';
import intl from 'react-intl-universal';
import Text from '~components/text/text';
import './ErrorBox.scss';

interface IProps {
  possibleSolution?: string;
}

const ErrorBox = ({ possibleSolution }: IProps) => (
  <div className="error-container">
    <h1>{intl.get('SOMETHING_WENT_WRONG')}</h1>
    {possibleSolution && <Text size="small">{possibleSolution}</Text>}
  </div>
);

export default ErrorBox;
