import React from 'react';
import cx from 'classnames';
import './text.scss';

interface IProps {
  size?: 'xsmall' | 'small' | 'normal' | 'large';
  bold?: boolean;
  secondary?: boolean;
  children: React.ReactNode;
  inline?: boolean;
  uppercase?: boolean;
  color?: 'inverted' | 'gray' | 'normal';
}

const Text = ({
  size = 'normal',
  inline = false,
  bold = false,
  secondary = false,
  uppercase = false,
  color = 'normal',
  children,
}: IProps) => {
  const classNames = cx('text-component', {
    'text-component__large': size === 'large',
    'text-component__small': size === 'small',
    'text-component__x-small': size === 'xsmall',
    'text-component__bold': bold,
    'text-component__secondary': secondary,
    'text-component__uppercase': uppercase,
    'text-component__inverted': color === 'inverted',
    'text-component__gray': color === 'gray',
  });

  return inline ? (
    <span className={classNames}>{children}</span>
  ) : (
    <p className={classNames}>{children}</p>
  );
};

export default React.memo(Text);
