import React, { useState, useEffect, useContext } from 'react';
import intl from 'react-intl-universal';
import CustomInput from '~components/customInput/customInput';
import { IProfileInformation } from '~interfaces/userData';
import ProfileEditContext from '~contexts/profileEditContext';
import './profileEditForm.scss';

interface IProps {
  onChange: React.Dispatch<
  React.SetStateAction<{
    userInfo: IProfileInformation;
    isValid: boolean;
  }>
  >;
}

const ProfileEditForm = ({ onChange }: IProps) => {
  const profileContext = useContext(ProfileEditContext);
  const {
    form: { firstName, lastName, email },
  } = profileContext;
  const [formValid, setFormValid] = useState(false);

  const checkFormValid = () => {
    const form = Object.values(profileContext.form);
    for (let field = 0; field < form.length; field += 1) {
      if (form[field].invalidReason || !form[field].value) return false;
    }
    return true;
  };

  useEffect(() => {
    setFormValid(checkFormValid);
  });

  useEffect(() => {
    onChange({
      userInfo: {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
      },
      isValid: formValid,
    });
  }, [firstName.value, lastName.value, email.value, formValid]);

  return (
    <div className="adyenForm-container">
      <form>
        <div className="adyenForm-row">
          <CustomInput
            inputName={firstName.label}
            placeholder={intl.get('FIRST_NAME')}
            value={firstName.value}
            invalidReason={firstName.invalidReason}
          />
          <CustomInput
            inputName={lastName.label}
            placeholder={intl.get('LAST_NAME')}
            value={lastName.value}
            invalidReason={lastName.invalidReason}
          />
        </div>
        <div className="adyenForm-row">
          <CustomInput
            type="email"
            inputName={email.label}
            placeholder={intl.get('EMAIL')}
            value={email.value}
            invalidReason={email.invalidReason}
          />
        </div>
      </form>
    </div>
  );
};

export default ProfileEditForm;
