import React, { useContext, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import BackButtonHeader from '~components/backButtonHeader/backButtonHeader';
import ListItem from '~components/listItem/listItem';
import { Store } from '../store';
import LinkItem from '~components/linkItem/linkItem';
import BillingInformationCard from '~components/billingInformationCard/billingInformationCard';
import { Api } from '../repositories/api';
import '~shared/styles/webView.scss';
import '~shared/styles/errorMessage.scss';
import ProcessingSmall from '~components/processingSmall/processingSmall';

enum ProcessingState {
  NotStarted = 'not_started',
  Pending = 'pending',
  Failed = 'failed',
  Succeeded = 'succeeded',
}

const PaymentProfileView = () => {
  const { state, dispatch } = useContext(Store);
  const [billingInfoState, setBillingInfoState] = useState(
    !state.user?.billingAddress ? ProcessingState.NotStarted : ProcessingState.Succeeded,
  );

  useEffect(() => {
    if (billingInfoState === ProcessingState.NotStarted) {
      setBillingInfoState(ProcessingState.Pending);
      Api.getBillingAddress(state.user?.accessToken)
        .then((response) => {
          state.setUserDataValue('billingAddress', response, dispatch);
          setBillingInfoState(ProcessingState.Succeeded);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          setBillingInfoState(ProcessingState.Failed);
        });
    }
  }, [billingInfoState]);

  const BillingInformation = () => {
    if (billingInfoState === ProcessingState.Pending) {
      return <ProcessingSmall altText="loading billing information" />;
    }
    if (billingInfoState === ProcessingState.Succeeded) {
      return (
        <>
          <BillingInformationCard billingAddress={state.user?.billingAddress} />
          <LinkItem
            title={
              state.user?.cardReferences
                ? intl.get('PAYMENT_PROFILE__CHANGE_PAYMENT_METHOD')
                : intl.get('NEW_PAYMENT__ENTER_PAYMENT_METHOD')
            }
            to={{ pathname: '/account/payment/edit' }}
          />
        </>
      );
    }
    return (
      <>
        <div className="error-message-container">
          <p>{intl.get('PAYMENT_PROFILE__BILLING_INFORMATION_ERROR')}</p>
        </div>
        <LinkItem
          title={
            state.user?.cardReferences
              ? intl.get('PAYMENT_PROFILE__CHANGE_PAYMENT_METHOD')
              : intl.get('NEW_PAYMENT__ENTER_PAYMENT_METHOD')
          }
          to={{ pathname: '/account/payment/edit' }}
        />
      </>
    );
  };

  return (
    <div className="web-view-container">
      <div className="row">
        <BackButtonHeader title={intl.get('PAYMENT_PROFILE__TITLE')} to="/account" />
      </div>

      <section className="row section-wrapper">
        <h2 className="section-title">{intl.get('PAYMENT_PROFILE')}</h2>

        <ListItem title={intl.get('EMAIL')} description={state.user?.email} />
        <div className="row">
          <h3>{intl.get('CARD_DETAILS')}</h3>
        </div>
        {state.user?.cardReferences && (
          <>
            <ListItem
              title={intl.get('PAYMENT_PROFILE__SAVED_PAYMENT_METHOD')}
              description={state.user?.cardRefLabel}
              borderBottom
            />
            <div className="row">
              <h3>{intl.get('CARD_DETAILS__CUSTOMER_INFORMATION')}</h3>
            </div>
            <BillingInformation />
          </>
        )}
        {!state.user?.cardReferences && (
          <>
            <p>
              <p>{intl.get('PAYMENT_PROFILE__NO_SAVED_CREDITCARD')}</p>
            </p>
            <LinkItem
              title={intl.get('NEW_PAYMENT__ENTER_PAYMENT_METHOD')}
              to={{ pathname: '/account/payment/edit' }}
            />
          </>
        )}
      </section>
    </div>
  );
};

export default PaymentProfileView;
