import React from 'react';
import intl from 'react-intl-universal';
import LoadingSpinner from './loading-bars.gif';
import './priceInfo.scss';

interface IProps {
  price?: number;
  unitString?: string;
}

const PriceInfo = ({ price, unitString }: IProps) => (
  <div className="price-info">
    <div className={`title ${price && unitString ? 'black' : 'gray'}`}>
      <h3>{intl.get('PRICE_INFO__SUM')}</h3>
    </div>
    {price && unitString ? (
      <div className="amount">
        <h3>{`${price} ${unitString}`}</h3>
      </div>
    ) : (
      <div className="icon">
        <img src={LoadingSpinner} alt="Loading price" />
      </div>
    )}
  </div>
);

export default PriceInfo;
