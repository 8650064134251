import React, { useContext } from 'react';
import intl from 'react-intl-universal';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Store } from '../store';
import { IAddress } from '~interfaces/userData';
import CustomSelect from '~components/customSelect/customSelect';
import Text from '~components/text/text';
import '~shared/styles/webView.scss';
import '~components/savedCardDetails/savedCardDetails.scss';
import '~shared/styles/formHeader.scss';

const AddressEditView = ({ history }: { history: { push: any } }) => {
  const schema = yup.object({
    street: yup
      .string()
      .required(intl.get('FIELD_VALIDATION__REQUIRED'))
      .max(70, intl.get('FIELD_VALIDATION__MIN_LENGTH', { property: 70 })),
    postalCode: yup
      .string()
      .required(intl.get('FIELD_VALIDATION__REQUIRED'))
      .max(70, intl.get('FIELD_VALIDATION__MIN_LENGTH', { property: 70 })),
    city: yup
      .string()
      .required(intl.get('FIELD_VALIDATION__REQUIRED'))
      .max(70, intl.get('FIELD_VALIDATION__MIN_LENGTH', { property: 70 })),
    country: yup.string().required(intl.get('FIELD_VALIDATION__REQUIRED')),
  });

  const { state, dispatch } = useContext(Store);

  const handleCloseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    history.push('/account/profile');
  };

  const handleSubmit = async (values: IAddress) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    state.setUserDataValue('address', values, dispatch);
    history.push('/account/profile');
  };

  return (
    <Formik
      initialValues={(state.user ? state.user.address : {}) as IAddress}
      validationSchema={schema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({
        submitForm, isValid, dirty, isSubmitting,
      }) => (
        <Form>
          <div className="web-view-container">
            <div className="row">
              <div className="form-header-wrapper">
                <button type="button" className="btn btn--clickable" onClick={handleCloseClick}>
                  {intl.get('CANCEL')}
                </button>
                <div className="title">
                  <Text bold>{intl.get('PROFILE_EDIT__CHANGE', { property: 'adress' })}</Text>
                </div>
                <button
                  type="button"
                  className={`btn ${
                    !dirty || !isValid || isSubmitting ? 'btn--disabled' : 'btn--clickable'
                  }`}
                  onClick={submitForm}
                >
                  {intl.get('SAVE')}
                </button>
              </div>
            </div>
            <section className="row section-wrapper">
              <div className="list-layout">
                <CustomSelect label={intl.get('COUNTRY')} name="country">
                  <option value="SE">{intl.get('SWEDEN')}</option>
                  <option value="DK">{intl.get('DENMARK')}</option>
                </CustomSelect>
              </div>
            </section>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddressEditView;
