import React from 'react';
import Text from '../text/text';
import { Checkmark, AlertFilled, Canceled } from '~shared/svg-icons/index';
import './gridItemWithIcon.scss';
import { ISubscription } from '~interfaces/subscriptions';
import SubscriptionDetails from '~components/subscriptionDetails/subscriptionDetails';

interface IProps {
  pageTitle: string;
  subscriptions: ISubscription[];
}

const GridItemWithIcon = ({ pageTitle, subscriptions }: IProps) => (
  <div className="grid-item-wrapper">
    {pageTitle && <h2 className="title">{pageTitle}</h2>}
    {subscriptions.map((subscription) => (
      <div key={subscription.id} className="grid-item-container">
        <div className="grid-item">
          <div className="icon-wrapper">
            {['active'].includes(subscription.status) && <Checkmark />}
            {['canceled', 'paused'].includes(subscription.status) && <AlertFilled />}
            {['complete', 'payment_failed'].includes(subscription.status) && <Canceled />}
          </div>
          <div className="content-wrapper">
            <Text bold>{`${subscription.title} ${subscription.description}`}</Text>
          </div>
        </div>
        <SubscriptionDetails subscription={subscription} largeIcons />
      </div>
    ))}
  </div>
);

export default GridItemWithIcon;
