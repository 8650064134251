import { IUserContext, IActions } from '~interfaces/userContext';
import { IUserData } from '~interfaces/userData';

const initialState: IUserData = {
  givenName: 'Telia',
  familyName: 'Sense',
  email: '',
};

const userDataValueAction = (key: string, value: any, dispatch: any): void => {
  dispatch({
    reducer: 'user',
    type: 'USER_DATA_VALUE',
    payload: { key, value },
  });
};

export const userReducer = (state: any, action: IActions) => {
  switch (action.type) {
    case 'USER_DATA_VALUE':
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export const userContext = {
  user: initialState,
  setUserDataValue: (key: string, value: any, dispatch: any) => (
    userDataValueAction(key, value, dispatch)
  ),
} as IUserContext;
