import React, { useState } from 'react';

export interface IProfileEditContext {
  form: IProfileForm;
  setFieldValue: {
    (name: string, value: string): void;
  };
  setFieldInvalid: {
    (name: string, invalidReason: string): void;
  };
}

export interface IProfileForm {
  firstName: IInputField;
  lastName: IInputField;
  email: IInputField;
}
export interface IInputField {
  label: string;
  value: string;
  invalidReason: string;
}

const initalUserForm: IProfileEditContext = {
  form: {
    firstName: {
      label: 'firstName',
      value: '',
      invalidReason: '',
    },
    lastName: {
      label: 'lastName',
      value: '',
      invalidReason: '',
    },
    email: {
      label: 'email',
      value: '',
      invalidReason: '',
    },
  },
  setFieldValue: () => {},
  setFieldInvalid: () => {},
};

const ProfileEditContext = React.createContext<IProfileEditContext>(initalUserForm);

export const ProfileEditContextProvider = ({
  children,
  initialValue = initalUserForm,
}: {
  children: React.ReactChild;
  initialValue?: IProfileEditContext;
}) => {
  const [profileEditForm, setProfileEditForm] = useState(initialValue);

  const setFieldValue = (name: string, value: string) => setProfileEditForm((prev) => {
    const newContext = {
      ...prev,
    };
    newContext.form[name as keyof IProfileForm].value = value;
    return newContext;
  });

  const setFieldInvalid = (name: string, reason: string) => setProfileEditForm((prev) => {
    const newContext = {
      ...prev,
    };
    newContext.form[name as keyof IProfileForm].invalidReason = reason;
    return newContext;
  });

  return (
    <ProfileEditContext.Provider
      value={{
        ...profileEditForm,
        setFieldValue,
        setFieldInvalid,
      }}
    >
      {children}
    </ProfileEditContext.Provider>
  );
};

export default ProfileEditContext;
