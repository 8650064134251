import React, { useState, useEffect, useContext } from 'react';
import intl from 'react-intl-universal';
import FormContext from '~contexts/formContext';
import './AdyenStyledFormItem.scss';

interface IProps {
  placeholder: string;
  value: string;
  name: string;
  inputReadOnly?: boolean;
  invalidReason?: string | boolean;
  forceInputType?: string;
}

const AdyenStyledFormItem = ({
  placeholder,
  name,
  value,
  inputReadOnly = false,
  forceInputType,
  invalidReason,
}: IProps) => {
  const formContext = useContext(FormContext);
  const [fieldFilled, setFieldFilled] = useState('');
  const [fieldVisited, setFieldVisited] = useState(false);

  // Lägg till intl.get
  const checkFieldValid = (fieldName: string, fieldValue: string) => {
    if (fieldVisited) {
      let errorMessage = fieldValue
        ? ''
        : intl.get('FIELD_VALIDATION__REQUIRED', { fieldName: placeholder });

      if (forceInputType) {
        const field = document.getElementById(`label-${name}`) as HTMLInputElement;
        if (!field.checkValidity()) {
          errorMessage = intl.get('FIELD_VALIDATION__INVALID', { fieldName: placeholder });
        }
      }
      formContext.setFieldInvalid(fieldName, errorMessage);
    }
  };

  const filledInput = (input: string) => {
    if (input) {
      setFieldFilled('adyenFormItem-label--filled');
    } else {
      setFieldFilled('');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formContext.setFieldValue(name, event.target.value);
  };

  useEffect(() => {
    filledInput(value);
  }, [value]);

  useEffect(() => {
    checkFieldValid(name, value);
  }, [fieldVisited, value]);

  return (
    <div
      className={
        invalidReason
          ? 'adyenFormItem-field-wrapper adyenFormItem-field-wrapper--error'
          : 'adyenFormItem-field-wrapper'
      }
    >
      <label
        htmlFor={`label-${name}`}
        className={
          invalidReason
            ? 'adyenFormItem-label adyenFormItem-label--error'
            : `adyenFormItem-label ${fieldFilled}`
        }
      >
        <span className="adyenFormItem-label__text">{placeholder}</span>
        <input
          className="adyenFormItem-label__input"
          required
          placeholder=""
          readOnly={inputReadOnly}
          disabled={inputReadOnly}
          type={forceInputType ?? 'text'}
          name={name}
          value={value}
          onChange={handleInputChange}
          onBlur={() => setFieldVisited(true)}
          id={`label-${name}`}
        />
      </label>
      {invalidReason && <span className="adyenFormItem-error-message">{`${invalidReason}`}</span>}
    </div>
  );
};

export default AdyenStyledFormItem;
