import React from 'react';
import intl from 'react-intl-universal';
import { IBillingAddress } from '~interfaces/cardInfo';
import './billingInformationCard.scss';

interface IProps {
  billingAddress: IBillingAddress;
}

const BillingInformationCard = ({ billingAddress }: IProps) => (
  <div className="billing-info-container">
    <div className="billing-info-item-wrapper">
      <p className="billing-info-item">{intl.get('NAME')}</p>
      <p className="billing-info-item">{`${billingAddress.firstName} ${billingAddress.lastName}`}</p>
    </div>
    <div className="billing-info-item-wrapper">
      <p className="billing-info-item">{intl.get('ADDRESS')}</p>
      <p className="billing-info-item">{`${billingAddress.street}, ${billingAddress.postalCode}, ${billingAddress.city}`}</p>
    </div>
    <div className="billing-info-item-wrapper">
      <p className="billing-info-item">{intl.get('COUNTRY')}</p>
      <p className="billing-info-item">{billingAddress.country}</p>
    </div>
    <div className="billing-info-item-wrapper">
      <p className="billing-info-item">{intl.get('PHONE_NUMBER')}</p>
      <p className="billing-info-item">{billingAddress.telephone}</p>
    </div>
  </div>
);

export default BillingInformationCard;
