import React from 'react';

const ArrowLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <title>TC</title>
    <g id="ARTWORK">
      <path d="M56,32a2.0001,2.0001,0,0,1-2,2H14.82812L27.41406,46.58594a1.99978,1.99978,0,1,1-2.82812,2.82812l-16-16a1.99926,1.99926,0,0,1,0-2.82812l16-16a1.99978,1.99978,0,0,1,2.82812,2.82812L14.82812,30H54A2.0001,2.0001,0,0,1,56,32Z" />
    </g>
  </svg>
);

export default ArrowLeft;
